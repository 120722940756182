/* eslint-disable @kaminrunde/firescout/jsx-expression-needs-state */
// @flow
import * as React from 'react'
import styled from 'styled-components'
import Layout from 'containers/Layout'
import preprocessStory from 'storybook/preprocessStory'
import Story from 'storybook/Story'
import config from 'config'
import * as components from 'theme/organisms'

type Props = {
  location: {
    search: string
    key: string
  }
}

export default function Preview(props: Props) {
  const story: any = useStory(props.location.search)
  const typeMatch = props.location.search.match(/type=([^&]*)/)
  const type = typeMatch ? typeMatch[1] : ''

  if(story) {
    story.componentDict = {}
    for(const name in components) {
      story.componentDict[name] = components[name]
    }
  }

  return (
    <Layout partialStateUpdates={story && story.partialStateUpdates}>
      {type !== 'static-content-block' && <div style={{ height: 40 }} />}
      {Boolean(story) || <Loading>Loading...</Loading>}
      {Boolean(story) && (
        <Wrapper>
          <Story key={props.location.key} story={story} small={type === 'magazine-article'} />
        </Wrapper>
      )}
    </Layout>
  )
}

function useStory(search) {
  const isCategory = Boolean(search.match(/type=level-.-product-category/))
  const match = search.match(/entryId=([^&]*)/)
  const entryId = match ? match[1] : ''
  const source = {
    internal: {
      type: isCategory ? 'Category' : 'OTHER',
    },
    story: null,
  }

  const [story, setStory] = React.useState<any>(null)

  React.useEffect(() => {
    fetch(config.apiEndpoints.contentfulPreview + '/' + entryId)
      .then((res) => res.json())
      .then((result) => {
        source.story = result.story
        return result.story
      })
      .then((story) =>
        preprocessStory(
          story,
          '',
          (name) => {
            try {
              // $FlowFixMe
              return require(`theme/organisms/${name}/request`)
            } catch (e) {
              return null
            }
          },
          source
        )
      )
      .then(setStory)
  }, [entryId])

  return story
}

const Loading = styled.div`
  text-align: center;
  margin: 100px 0;
`

const Wrapper = styled.div`
  margin: 0;
`
