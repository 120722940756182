// @flow
import * as React from 'react'
import styled from 'styled-components'
import Gallery from 'theme/molecules/ImageGallery'
import Embedly from 'theme/organisms/Embedly'

type Props = {
  items: {
    src: string
    placeholder: string
  }
  autoPlay: boolean
}

export default function EmbedlyGallery(props: Props) {
  return (
    <Wrapper className="EmbedlyGallery">
      <Gallery
        items={props.items}
        autoPlay={props.autoPlay}
        showThumbnails={false}
        useBrowserFullscreen={false}
        showPlayButton={false}
        showFullscreenButton={false}
        slideInterval={8000}
        lazyLoad={true}
        renderItem={(item) => <Embedly {...item} />}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div``
