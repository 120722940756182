import { makeHtml } from 'utils/transformMarkdown'

export const preprocessProps = (props) => {
  return Object.assign({}, props, {
    unsubscribedSubTitle: props.unsubscribedSubTitle ? makeHtml(props.unsubscribedSubTitle) : '',
  })
}

export const versionUpdate = (props) => {
  props = Object.assign({}, props)

  /**
    Migration 25.03.2021: editable title
   */
  if (!props.__version) {
    props.__version = 1
    props.title = 'Werde zum Wundercurves-Insider und sichere Dir jetzt den Newsletter!'
  }

  return props
}
