import config from 'config'

let byCatDict: Record<string, string> = {}
let catDictInit = false

export function catToObjectId(cat: string): string | null {
  if (!catDictInit) {
    for (let key in config.categories) {
      byCatDict[config.categories[key].categoryPath] = key
    }
    catDictInit = true
  }
  return byCatDict[cat] || null
}
