import toBase64 from 'utils/toBase64'
import { makeHtml } from 'utils/transformMarkdown'
import { createOptimizedImage, transformExistingImgSrc } from 'utils/imgOptimization'
import * as t from './types'

export const createContext = async (props: t.Props, { getGridContext }) => {
  const gridContext = getGridContext()
  let images: any = []
  for (let i in props.images) {
    images.push({
      ...props.images[i],
      base64: await toBase64(props.images[i].src),
      src: createOptimizedImage(props.images[i].src, gridContext),
    })
  }
  return { images: images }
}

export const preprocessProps = async (props: t.Props) => ({
  ...props,
  label: makeHtml(props.label),
})

export const versionUpdate = (props: t.Props) => {
  props = Object.assign({}, props)

  /**
    Migration 11.09.2019: labelLink prop has been removed
   */
  if (!props.__version) {
    props.__version = 1
    if (props.labelLink && !props.label.includes('[')) {
      props.label = `[${props.label}](${props.labelLink})`
    }
    delete props.labelLink
  }
  /**
    Migration 01.05.2021: Version 2 - transform old ImgSources for Image optimization
  */
  if (props.__version === 1) {
    for (let i in props.images) {
      props.images[i].src = transformExistingImgSrc(props.images[i].src)
    }
  }

  return props
}
