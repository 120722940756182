import * as t from './types'
import toBase64 from 'utils/toBase64'
import { createOptimizedImage, transformExistingImgSrc } from 'utils/imgOptimization'
import contentfulBatchFetch from 'utils/contentfulBatchFetch'

type Author = {
  firstname: string,
  lastname: string,
  metaDescription: string,
  objectID: string,
  teaser: string,
  teaserPictureUrl: string
  urlKey: string
}

export async function createContext(props: t.UserConfig, { getGridContext }): Promise<t.Context> {
  const gridContext = getGridContext()
  if (!props.authorId)
    return {
      authorName: '',
      authorImgUrl: '',
      authorImgBase64: '',
      authorLink: '',
      imgBase64: '',
      optImg: createOptimizedImage(props.imgSrc, gridContext),
    }


  const hits: Author[] = await contentfulBatchFetch('author')
  const author = hits.find((author) => author.objectID === props.authorId)

  if (!author)
    return {
      authorName: '',
      authorImgUrl: '',
      authorImgBase64: '',
      authorLink: '',
      imgBase64: '',
      optImg: createOptimizedImage(props.imgSrc, gridContext),
    }

  const [author64, img64] = await Promise.all([
    toBase64(author.teaserPictureUrl),
    toBase64(props.imgSrc),
  ])

  return {
    authorName: author.firstname,
    authorImgUrl: author.teaserPictureUrl,
    authorImgBase64: author64,
    authorLink: `/author/${author.urlKey}`,
    imgBase64: img64,
    optImg: createOptimizedImage(props.imgSrc, gridContext),
  }
}

export const versionUpdate = (props: t.Props) => {
  props = Object.assign({}, props)

  if (!props.__version) {
    props.__version = 1
    props.imgSrc = transformExistingImgSrc(props.imgSrc)
  }

  return props
}
