import { makeHtml } from 'utils/transformMarkdown'
import toBase64 from 'utils/toBase64'
import { createOptimizedImage, transformExistingImgSrc } from 'utils/imgOptimization'
import * as t from './types'

export const createContext = async (props: t.Props, { getGridContext }) => {
  const gridContext = getGridContext()
  let images: any = []
  for (let i in props.imageGalleryProps.images) {
    images.push({
      ...props.imageGalleryProps.images[i],
      base64: await toBase64(props.imageGalleryProps.images[i].src),
      src: createOptimizedImage(props.imageGalleryProps.images[i].src, gridContext),
    })
  }
  return { images: images }
}

export const preprocessProps = (props: t.Props) =>
  Object.assign({}, props, {
    textProps: {
      ...props.textProps,
      children: makeHtml(props.textProps.children),
    },
    imageGalleryProps: {
      ...props.imageGalleryProps,
      label: makeHtml(props.imageGalleryProps.label),
    },
  })

export const versionUpdate = (props: t.Props) => {
  /**
    Migration 11.09.2019: labelLink prop has been removed
   */
  if (!props.__version) {
    props = Object.assign({}, props)
    props.__version = 1
    if (props.imageGalleryProps.labelLink && !props.imageGalleryProps.label.includes('[')) {
      props.imageGalleryProps = Object.assign({}, props.imageGalleryProps)
      props.imageGalleryProps.label = `[${props.imageGalleryProps.label}](${props.imageGalleryProps.labelLink})`
    }
    delete props.imageGalleryProps.labelLink
  }
  /**
    Migration 01.05.2021: Version 2 - transform old ImgSources for Image optimization
  */
  if (props.__version === 1) {
    for (let i in props.imageGalleryProps.images) {
      props.imageGalleryProps.images[i].src = transformExistingImgSrc(
        props.imageGalleryProps.images[i].src
      )
    }
  }

  return props
}
