import * as React from 'react'
import styled from 'styled-components'
import * as t from './types'
import { ms } from 'modules/browser/const'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import ty from 'utils/typographie'
import { useSquarelovinId } from 'modules/ui'
import LazyImg from 'theme/atoms/LazyImg'
import Link from 'theme/atoms/Link'

const whitePixel =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2P4////fwAJ+wP9BUNFygAAAABJRU5ErkJggg=='

export default function SquarelovinTagged(props: t.Props) {
  const firstRow = React.useRef<null | HTMLDivElement>(null)
  const entriesEl = React.useRef<null | HTMLDivElement>(null)
  const [scrollPosition, setScrollPosition] = React.useState(0)
  const sq = useSquarelovinId()

  React.useEffect(() => {
    if (!firstRow.current || !entriesEl.current) return
    const el = firstRow.current
    const style = window.getComputedStyle(el)
    const rect = el.getBoundingClientRect()
    const margin = parseInt(style.marginRight.replace('px', ''))
    entriesEl.current.scroll({
      left: scrollPosition * (rect.width + margin),
      behavior: 'smooth',
    })
  }, [scrollPosition])

  /** @firescoutMockFn organisms-SquarelovinTagged.handleEntryClick */
  const handleEntryClick = (entry: t.Entry) => {
    sq.set(entry.id)
  }

  return (
    <Wrapper
      className="SquarelovinTagged full-width-MOBILE full-width-TABLET"
      data-cy-ctx="organisms/SquarelovinTagged"
    >
      {props.title && (
        <h3 data-cy-state="has-title" id={props.title.toLowerCase().replace(/[^a-z0-9]/g, '')}>
          {props.title}
        </h3>
      )}
      <div className="slider-wrapper">
        <div className="list-wrapper" ref={entriesEl}>
          <div className="entries">
            {props.context.hits.map((row, i) => (
              <div
                className="row"
                key={i}
                data-cy-handle="inspect"
                onClick={() => handleEntryClick(row)}
                ref={i === 0 ? firstRow : undefined}
              >
                <LazyImg src={row.img} default={whitePixel} alt={row.id} />
                <div className="autor">
                  <span>{row.userName}</span>
                </div>
                <button>Jetzt shoppen</button>
              </div>
            ))}
            <div className="expand" />
          </div>
        </div>
        {props.button && (
          <div className="button">
            <Link to={props.link}>
              {props.buttonText}
            </Link>
          </div>
        )}
        {scrollPosition > 0 && (
          <div
            className="slider left"
            data-cy-state="has-prev-images"
            data-cy-handle="left-btn"
            onClick={() => setScrollPosition((x) => x - 1)}
          >
            <FiChevronLeft />
          </div>
        )}
        {scrollPosition < props.context.hits.length - 3 && (
          <div
            className="slider right"
            data-cy-state="has-next-images"
            data-cy-handle="right-btn"
            onClick={() => setScrollPosition((x) => x + 1)}
          >
            <FiChevronRight />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  max-width: 100vw;

  @media (min-width: ${ms.LAPTOP}px) {
    max-width: var(--container-size);
  }

  > h3 {
    color: #262626;
    ${ty([26, 0.46, 36], 'Cormorant Garamond', '500')}
    @media (min-width: ${ms.LAPTOP}px) {
      ${ty([32, 0.62, 46])}
    }
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  > .slider-wrapper {
    position: relative;
  max-width: 100vw;

  @media (min-width: ${ms.LAPTOP}px) {
    max-width: var(--container-size);
  }

  > .button {
    margin-top: 20px;
    > a, > span {
      color: #ffffff;
      font-family: Raleway;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 2.36px;
      line-height: 25px;
      background: #555555;
      text-transform: uppercase;
      margin: 0 auto;
      padding: 8px 20px 8px 20px;
      border: none;
      cursor: pointer;
      text-align: center;
      display: block;

      @media (max-width: ${ms.LAPTOP}px) {
        max-width: 320px;
        margin: 20px auto;
      }
      @media (min-width: ${ms.LAPTOP}px) {
        max-width: 320px;
      }
    }
  }

  > .list-wrapper {
    width: 100%;
    padding-left: 15px;
    overflow: scroll;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
    @media (min-width: ${ms.TABLET}px) {
      padding-left: 20px;
    }
    @media (min-width: ${ms.LAPTOP}px) {
      padding-left: 0;
      overflow: hidden;
    }

    > .entries {
      display: flex;
      > .row {
        cursor: pointer;
        margin-right: 25px;
        min-width: 60vw;
        ${ty([11, 2.4, 20], 'Raleway')}
        @media (min-width: ${ms.TABLET}px) {
          min-width: 35vw;
        }
        @media (min-width: ${ms.LAPTOP}px) {
          min-width: calc((100% / 2) - 13px);
        }
        @media (min-width: ${ms.LAPTOP_L}px) {
          min-width: calc((100% / 3) - 16px);
        }
        > img {
          width: 100%;
          display: block;
        }

        > .autor {
          color: #262626;
          text-align: center;
          position: relative;
          height: 40px;
          > span {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            padding: 10px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        > button {
          background: rgb(248, 247, 248);
          color: #262626;
          border: none;
          padding: 10px;
          display: block;
          width: 100%;
          text-transform: uppercase;
          cursor: pointer;
          ${ty([11, 2.4, 20], 'Raleway', 'bold')}
        }
      }
      .expand {
        min-width: 1px;
      }
    }
  }


  > .slider {
    position: absolute;
    cursor: pointer;
    bottom: 55%;
    width: 50px;
    height: 50px;
    background: rgb(248, 247, 248, 0.8);
    border: 1px solid #f8f7f8;
    border-radius: 50%;
    display: none;
    align-items: center;
    justify-content: center;
    @media (min-width: ${ms.LAPTOP}px) {
      display: flex;
    }
    &.left {
      left: -25px;
    }
    &.right {
      right: -25px;
    }
    > svg {
      font-size: 25px;
    }
  }
  }
`
