// const defaultRawStory = {
//   GRID_GAP: {
//     MOBILE_M: 10,
//     MOBILE_L: 20,
//     TABLET: 20,
//     LAPTOP: 10,
//     LAPTOP_L: 10,
//     LAPTOP_XL: 10,
//   },
//   GRID: {
//     MOBILE_M: {
//       active: true,
//       content: `
//         " Headline    Headline    Headline    Headline    " auto
//         " ProductList ProductList ProductList ProductList " auto
//         " Seo_Text    Seo_Text    Seo_Text    Seo_Text    " auto
//         /   1fr          1fr        1fr         1fr
//       `,
//       components: [
//         'Headline',
//         'ProductList',
//         'Seo_Text'
//       ],
//       abTest: {
//         a: {
//           active: true,
//           content: `
//             " ProductList ProductList ProductList ProductList " auto
//             " Seo_Text    Seo_Text    Seo_Text    Seo_Text    " auto
//             /   1fr          1fr        1fr         1fr
//           `,
//           components: [
//             'ProductList',
//             'Seo_Text'
//           ],
//         },
//         b: {
//           active: true,
//           content: `
//             " ProductList ProductList ProductList ProductList " auto
//             /   1fr          1fr        1fr         1fr
//           `,
//           components: [
//             'ProductList'
//           ],
//         }
//       }
//     },
//     MOBILE_L: {
//       active: true,
//       content: `
//         " Banner_1    Banner_2    Banner_3    Banner_4    " auto
//         " Headline    Headline    Headline    Headline    " auto
//         " ProductList ProductList ProductList ProductList " auto
//         /   1fr          1fr        1fr         1fr
//       `,
//       components: [
//         'Banner_1',
//         'Banner_2',
//         'Banner_3',
//         'Banner_4',
//         'Headline',
//         'ProductList'
//       ]
//     },
//     TABLET: {
//       active: false,
//       content: ``
//     },
//     LAPTOP: {
//       active: false,
//       content: ``
//     },
//     LAPTOP_L: {
//       active: false,
//       content: ``
//     },
//     LAPTOP_XL: {
//       active: true,
//       content: `
//         " Banner_1    Banner_2    Banner_3    Banner_4    " auto
//         " Headline    Headline    Headline    Headline    " auto
//         " ProductList ProductList ProductList ProductList " auto
//         " Seo_Text    Seo_Text    Seo_Text    Seo_Text    " auto
//         /   1fr          1fr        1fr         1fr
//       `,
//       components: [
//         'Banner_1',
//         'Banner_2',
//         'Banner_3',
//         'Banner_4',
//         'Headline',
//         'ProductList',
//         'Seo_Text'
//       ]
//     }
//   }
// }

export function tokenize(grid) {
  grid = grid + ' '
  let rows = []
  let columns = []
  let widths = []
  let heights = []

  let inComponent = false
  let inWidths = false
  let word = ''
  let i

  for (i = 0; i < grid.length; i++) {
    const char = grid[i].replace('\n', ' ')

    if (char === '"') {
      if (inComponent) {
        rows.push(columns)
      } else {
        columns = []
      }
      inComponent = !inComponent
      continue
    }
    if (char === '/') {
      inWidths = true
      continue
    }

    if (char !== ' ') {
      word += char
      continue
    }

    if (char === ' ' && word) {
      if (inComponent) {
        columns.push(word)
      } else if (inWidths) {
        widths.push(word)
      } else {
        heights.push(word)
      }
      word = ''
    }
  }

  return { rows, widths, heights }
}

function format(grid) {
  let components = {}
  grid.rows.forEach((row, i) =>
    row.forEach((component, j) => {
      if (!components[component])
        components[component] = [
          [i + 1, j + 1],
          [i + 2, j + 2],
        ]
      components[component][1][0] = i + 2
      components[component][1][1] = j + 2
    })
  )
  for (let name in components) {
    const c = components[name]
    components[name] = `${c[0][0]} / ${c[0][1]} / ${c[1][0]} / ${c[1][1]}`
  }
  return {
    templateRows: grid.heights.join(' '),
    templateColumns: grid.widths.join(' '),
    components: components,
  }
}

function createGrid(grid, gap) {
  let css = `
    grid-template-columns: ${grid.templateColumns};
    grid-template-rows: ${grid.templateRows};
    grid-gap: ${gap}px;
    > .CmsWrapper {display:none};
    ${Object.entries(grid.components)
      .map(([name, val]) => {
        return `> .${name} {grid-area: ${val}; display:flex;}`
      })
      .join('\n')}
  `

  return css
}

export default function createCss(rawStory, source) {
  const create = (grid, gap) => {
    let result = tokenize(grid.content)
    result = cutOutFashionRoute(result, source)
    result = format(result)
    result = createGrid(result, gap)
    if (grid.abTest) {
      let aResult = tokenize(grid.abTest.a.content)
      let bResult = tokenize(grid.abTest.b.content)
      aResult = format(aResult)
      bResult = format(bResult)
      aResult = createGrid(aResult, gap)
      bResult = createGrid(bResult, gap)
      result +=
        '\n' +
        `
        body.ab-mode-a & {${aResult}}
        body.ab-mode-b & {${bResult}}
      `
    }
    return result
  }
  let css = create(rawStory.GRID.MOBILE_M, rawStory.GRID_GAP.MOBILE_M)
  if (rawStory.GRID.MOBILE_L.active) {
    css += `\n@media(min-width:375px){${create(
      rawStory.GRID.MOBILE_L,
      rawStory.GRID_GAP.MOBILE_L
    )}}`
  }
  if (rawStory.GRID.TABLET.active) {
    css += `\n@media(min-width:525px){${create(rawStory.GRID.TABLET, rawStory.GRID_GAP.TABLET)}}`
  }
  if (rawStory.GRID.LAPTOP.active) {
    css += `\n@media(min-width:768px){${create(rawStory.GRID.LAPTOP, rawStory.GRID_GAP.LAPTOP)}}`
  }
  if (rawStory.GRID.LAPTOP_L.active) {
    css += `\n@media(min-width:990px){${create(
      rawStory.GRID.LAPTOP_L,
      rawStory.GRID_GAP.LAPTOP_L
    )}}`
  }
  if (rawStory.GRID.LAPTOP_XL.active) {
    css += `\n@media(min-width:1200px){${create(
      rawStory.GRID.LAPTOP_XL,
      rawStory.GRID_GAP.LAPTOP_XL
    )}}`
  }

  return css
    .replace(/\n/g, ' ')
    .trim()
    .replace(/\s\s+/g, ' ')
}

function cutOutFashionRoute(result, source) {
  if (source.internal.type !== 'Category') return result
  const rawStory = source.story
  const component = rawStory.COMPONENTS.find((c) => c.name === 'FashionProductList')
  if (!component) return result

  let rows = []
  let heights = []
  const targetName = component.props.gridArea

  let skip = true
  result.rows.forEach((row, i) => {
    if(row[0] === 'Text-Promo') {
      rows.push(row)
      heights.push(result.heights[i])
    }
    if (row[0] === targetName) skip = false
    if (skip) return
    else {
      rows.push(row)
      heights.push(result.heights[i])
    }
  })

  return {
    rows: rows,
    widths: result.widths,
    heights: heights,
  }
}
// console.log(createCss(defaultRawStory))
