import createCss, {tokenize} from './createCss'
import {MediaSize} from 'modules/browser/types'

export default async function preprocessStory(rawStory, contentfulId, fetchRequest, source) {
  let story: any = {
    dict: {},
    css: '',
    partialStateUpdates: [],
    components: [],
    contentfulId,
  }
  if (!rawStory) return story

  // remove unused components
  let componentDict: any = {}
  Object.values(rawStory.GRID).forEach((grid: any) => {
    if (!grid.active) return
    if (!grid.components) return
    grid.components.forEach((name) => (componentDict[name] = true))
  })
  const components = rawStory.COMPONENTS.filter((c) => componentDict[c.props.gridArea])

  const requestDict = await fetchRequestDict(rawStory, fetchRequest)
  const newComponents: any = await Promise.all(
    components.map((component) =>
      preprocessStoryComponent(
        component, 
        requestDict[component.name], 
        story.partialStateUpdates,
        () => createGridContext(component.props.gridArea, rawStory)
      )
    )
  )
  const byGridName = newComponents.reduce(
    (dict, next) => (dict[next.props.gridArea] = next.id) && dict,
    {}
  )

  {
    // create component list by mobile grid order first (to prevent flickering)
    let mobileComponentsDict = {}
    let components = new Set()
    try {
      rawStory.GRID.MOBILE_M.content
        .replace(/\/.*/, '')
        .match(/".*"/g)
        .map((row) => row.match(/[A-z0-9-]+/g))
        .forEach(
          (row) =>
            row &&
            row.forEach((component) => {
              const id = byGridName[component]
              if (id) {
                mobileComponentsDict[id] = true
                components.add(id)
              }
            })
        )
    } catch (e) {
      console.log('WARNING (preprocessStory) unable to extract mobile components')
    }

    Object.values(byGridName).forEach((id: any) => {
      if (!mobileComponentsDict[id]) components.add(id)
    })
    story.components = Array.from(components)
    newComponents.forEach((component) => (story.dict[component.id] = component))
  }

  story.css = createCss(rawStory, source)

  return story
}

async function preprocessStoryComponent(component, request, partialStateUpdates, getGridContext) {
  if (!request) return component
  const store = null //createStore()
  let props = component.props
  if (request.versionUpdate) props = request.versionUpdate(props)
  if (request.preprocessProps) props = await request.preprocessProps(props)
  if (request.createContext) props.context = await request.createContext(props, {getGridContext})
  if (request.createPartialStateUpdates) {
    const updates = await request.createPartialStateUpdates(store, props)
    partialStateUpdates.push(...updates)
  }
  return Object.assign({}, component, { props })
}

export type GridContext = {
  minRow: number
  maxRow: number
  byMediaSize: Partial<Record<MediaSize, {
    row: number
    col: number
    allRows: string[] 
    allCols: string[]
    colStretch: number
    rowStretch: number
  }>>
}

function createGridContext (gridArea:string, rawStory):GridContext {
  let ctx:GridContext = {minRow: 0, maxRow:0, byMediaSize:{}}
  
  for(const ms in rawStory.GRID) {
    const rawGrid = rawStory.GRID[ms]
    if(!rawGrid.active) continue
    const grid = tokenize(rawGrid.content)

    const msCtx = {
      row: -1,
      col: -1,
      allRows: grid.heights,
      allCols: grid.widths,
      colStretch: 0,
      rowStretch: 0
    }
    ctx.byMediaSize[ms] = msCtx

    for(let row=0;row<grid.rows.length; row++) {
      for(let col=0;col<grid.rows[row].length;col++) {
        const area = grid.rows[row][col]

        if(area === gridArea) {
          if(msCtx.row === -1) msCtx.row = row
          if(msCtx.col === -1) msCtx.col = col
          msCtx.colStretch = col - msCtx.col +1
          msCtx.rowStretch = row - msCtx.row +1
        }
      }
    }

    if(msCtx.row < ctx.minRow) ctx.minRow = msCtx.row
    if(msCtx.row > ctx.maxRow) ctx.maxRow = msCtx.row
  }
  
  return ctx
}

async function fetchRequestDict(rawStory, fetchRequest) {
  const organisms = rawStory.COMPONENTS.map((c) => c.name)
    .sort()
    .filter((s, i, a) => s !== a[i - 1])
  const requests = await Promise.all(organisms.map(fetchRequest))
  let requestDict = {}
  for (let i = 0; i < organisms.length; i++) requestDict[organisms[i]] = requests[i]
  return requestDict
}
