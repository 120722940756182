import * as React from 'react'
import styled from 'styled-components'
import * as t from './types'
import Link from 'theme/atoms/Link'
import ty from 'utils/typographie'
import { ms } from 'modules/browser/const'
import Arrow from 'svg/Arrow'
import useInView from 'hooks/useInView'
import * as actions from './actions'

export default function CategoryTeaserList(props: t.Props) {
  const [ref, inView] = useInView(200, true)
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => props.eecTrack && actions.click(props)

  return (
    <Wrapper numItems={props.categories.length} ref={ref} onClick={handleClick}>
      <h3>{props.title}</h3>

      <div className="categories full-width">
        <div className="list">
          {props.categories.map((cat, i) => (
            <Category key={cat.label} to={cat.link}>
              <img src={inView ? cat.src : props.context.categoryBase64Urls[i]} />
              <div>
                <div>{cat.label}</div>
              </div>
            </Category>
          ))}
        </div>
      </div>

      {props.link && (
        <Link className="link-box" to={props.link}>
          <div className="disclaimer">Du willst noch mehr Auswahl?</div>
          <div className="label">
            direkt zum Shop <Arrow />
          </div>
        </Link>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > h3 {
    ${ty([26, 0.46, 34], 'Cormorant Garamond', '500')}
    margin: 0;
    text-align: center;
  }

  > .categories {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    display: flex;
    @media (min-width: ${(p) => (120 + 15) * p.numItems + 20}px) {
      justify-content: center;
    }
    > .list {
      margin-top: 30px;
      padding: 0 20px;
      display: flex;
    }
  }

  > a,
  > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 40px;
    height: 94px;
    max-width: 300px;
    width: 100%;
    padding: 15px;
    background: #eeeced;
    ${ty([18, 0.34, 26], 'Cormorant Garamond', '500')}
    color: #262626;

    > .disclaimer {
      color: #262626;
    }
    > .label {
      font-weight: bold;
      margin-top: 8px;
      font-style: italic;
      > svg {
        margin-left: 10px;
      }
    }
    @media (min-width: ${ms.LAPTOP_XL}px) {
      flex-direction: row;
      min-width: 500px;
      justify-content: space-between;
      padding: 0 30px;
      height: 50px;

      > .label {
        margin-top: 0;
      }
    }
  }
`

const Category = styled(Link)`
  display: block;
  min-width: 120px;
  max-width: 120px;
  height: 120px;
  margin-right: 15px;
  position: relative;

  @media (min-width: ${ms.LAPTOP_XL}px) {
    min-width: 170px;
    max-width: 170px;
    height: 170px;
    margin-right: 28px;
  }
  &:last-child {
    margin-right: 0;
  }

  > img {
    width: 100%;
    height: 100%;
    border-radius: 170px;
  }

  > div {
    ${ty([11, 2.4, 20], 'Raleway')}
    position: absolute;
    bottom: 34px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    > div {
      background: rgba(38, 38, 38, 0.8);
      color: white;
      padding: 0 10px;
      text-transform: uppercase;
    }
  }
`
