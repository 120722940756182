import useLazyImageSrc from 'hooks/useLazyImageSrc'
import * as React from 'react'
import styled, { css } from 'styled-components'
import * as t from './types'
import { colors } from './const'
import ty from 'utils/typographie'
import { ms } from 'modules/browser/const'
import useInView from 'hooks/useInView'
import * as actions from './actions'

export default function CategoryBannerRounded(props: t.Props) {
  const [ref, image] = useLazyImageSrc(
    props.context.optImg,
    props.context.base64,
    100,
  )
  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => props.eecTrack && actions.click(props)

  return (
    <Wrapper
      ref={wrapperRef}
      onClick={handleClick}
      backgroundColor={props.backgroundColor}
      overlayPositionTopBottom={props.overlayPositionTopBottom}
    >
      <div className="banner"></div>
      <Label
        title={props.title}
        subtitle={props.subtitle}
        overlayPositionTopBottom={props.overlayPositionTopBottom}
      >
        <div className="content">
          <div className="title">{props.title}</div>
          <div className="subtitle" dangerouslySetInnerHTML={{ __html: props.subtitle }} />
        </div>
        <img ref={ref} src={image} alt={props.alt}></img>
      </Label>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;

  > .banner {
    background: ${(props) => colors[props.backgroundColor]};
    position: absolute;
    width: 100%;
    height: 25%;

    ${(props) =>
      props.overlayPositionTopBottom === 'top'
        ? css`
            top: 0px;
            border-bottom-right-radius: 60% 100%;
            border-bottom-left-radius: 60% 100%;
          `
        : css`
            bottom: 0px;
            border-top-right-radius: 60% 100%;
            border-top-left-radius: 60% 100%;
          `};

    @media (min-width: ${ms.TABLET}px) {
      height: 20%;
    }

    @media (min-width: ${ms.LAPTOP}px) {
      height: 25%;
    }
  }
`
const Label = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  justify-items: center;

  > .content {
    z-index: 3;
    width: 90%;
    text-align: center;
    margin-top: 30px;

    ${(props) =>
      props.overlayPositionTopBottom === 'top'
        ? css`
            order: 0;
          `
        : css`
            order: 2;
          `};

    > .title {
      margin-bottom: 10px;
      > p {
        color: #262626;
      }
      ${ty([26, 0.46, 34], 'Cormorant Garamond', '500')}

      @media (min-width: ${ms.LAPTOP_L}px) {
        ${ty([36, 0.7, 48], 'Cormorant Garamond', '500')}
      }
    }
    > .subtitle {
      min-height: 60px;
      ${ty([13, 0.39, 20], 'Open Sans')}

      @media (min-width: ${ms.LAPTOP_L}px) {
        ${ty([18, 0.54, 24], 'Open Sans')}
      }

      > p {
        color: #262626;
        margin: 0px;
      }
    }
  }

  > img {
    width: 100%;
  }
`
