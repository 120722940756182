import * as React from 'react'
import styled from 'styled-components'
import Link from 'theme/atoms/Link'
import Gallery from 'theme/molecules/ImageGallery'
import Markdown from 'theme/atoms/Markdown'
import Image from 'theme/atoms/Image'
import * as t from './types'


export default function ImageGallery(props: t.Props) {
  const items = React.useMemo(() => {
    return props.context.images.map((img, i) => ({
      // eslint-disable-next-line react/display-name
      renderItem: () => (
        <div className="image-gallery-image">
          <Image alt={img.alt} src={img.src} base64={img.base64} />
          {img.label && (
            <Link to={img.labelLink} className="label">
              <span>{img.label}</span>
            </Link>
          )}
        </div>
      ),
    }))
  }, [props.context.images])

  return (
    <Wrapper className="ImageGallery">
      <Gallery
        items={items}
        autoPlay={props.autoPlay}
        showThumbnails={false}
        useBrowserFullscreen={false}
        slideInterval={8000}
        lazyLoad={true}
      />
      {props.label && (
        <div className="description">
          <Markdown centered children={props.label} />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > .description {
    p {
      margin: 0;
      font-size: 14px !important;
    }
  }

  .image-gallery-image > .label {
    position: absolute;
    left: 58px;
    right: 63px;
    bottom: 5px;
    display: flex;
    justify-content: center;
    background: none;

    > span {
      font-size: 12px;
      width: 100%;
      max-width: 380px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 15px;
      background: rgba(255, 255, 255, 0.8);
    }
  }
`
