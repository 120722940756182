import { makeHtml } from 'utils/transformMarkdown'
import toBase64 from 'utils/toBase64'
import { createOptimizedImage, transformExistingImgSrc } from 'utils/imgOptimization'
import * as t from './types'
import { orderedMediaSizes } from 'modules/browser/const'

export const createContext = async (
  props: t.UserConfig,
  { getGridContext }
): Promise<t.Context> => {
  const gridContext = getGridContext()
  let base64 = await toBase64(props.imageProps.src)
  let scale: Record<string, number> = {}
  let add = false
  for (let ms of orderedMediaSizes) {
    if (ms === props.imageBreak) add = true
    if (add) scale[ms] = (props.imageWidth || 50) / 100
  }
  return {
    base64: base64,
    optImg: createOptimizedImage(props.imageProps.src, gridContext, { scale }),
  }
}

export const preprocessProps = (props: t.Props) => {
  return Object.assign({}, props, {
    textProps: {
      ...props.textProps,
      children: makeHtml(props.textProps.children),
    },
    imageProps: {
      ...props.imageProps,
      label: makeHtml(props.imageProps.label),
    },
  })
}

export const versionUpdate = (props: t.Props) => {
  props = Object.assign({}, props)
  /**
    Migration 11.09.2019: labelLink prop has been removed
   */
  if (!props.__version) {
    props = Object.assign({}, props)
    props.__version = 1
    if (props.imageProps.labelLink && !props.imageProps.label.includes('[')) {
      props.imageProps = Object.assign({}, props.imageProps)
      props.imageProps.label = `[${props.imageProps.label}](${props.imageProps.labelLink})`
    }
    delete props.imageProps.labelLink
  }

  /**
    Migration 01.05.2021: transform old ImgSources for Image optimization
  */
  if (props.__version === 1) {
    props.__version = 2
    props.imageProps.src = transformExistingImgSrc(props.imageProps.src)
  }

  return props
}
