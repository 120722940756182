import * as React from 'react'
import DividerTemplate from 'theme/molecules/Divider'

export default function Divider(props: { big: boolean }) {
  return (
    <div>
      <DividerTemplate bigSpace={props.big} />
    </div>
  )
}
