import {deserialize} from 'modules/products/utils/filter-values-utils'
import {fetchHelper} from 'modules/products/utils/api'
import {DynamicFilterDefinition} from 'modules/products/types'
import {SearchResult} from 'utils/searchClient'
import {api} from 'modules/products/types'

/** @firescoutMockFn organisms-CurratedSearchWidget.fetchHits */
export async function fetchHits(hpp, queryStrings, page) {
  const hitsPerPage = Math.ceil(hpp / queryStrings.length)

  const results:SearchResult<api.Hit>[] = await Promise.all(queryStrings.map(async q => {
    const filterValues = deserialize('#'+q)
    filterValues.page = page
    const dynamicFilters:DynamicFilterDefinition[] = []
    for(const path in filterValues.dynamicFilters) {
      dynamicFilters.push({
        label: path.replace('tagGroups.', ''),
        path: path
      })
    }
    const helper = await fetchHelper(filterValues, dynamicFilters)
    return helper.search({hitsPerPage})
  }))
  
  const hitList = results.map((r) => r.hits)
  let hits:api.Hit[] = []
  for(const row of hitList) hits.push(...row)
  const totalPages = results.reduce((p, n) => (n.numPages > p ? p : n.numPages), 50)

  return [hits, totalPages] as [api.Hit[], number]
}
