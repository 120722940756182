/**
 * Squarelovin always injects its content. if no correct div was found the executing script will be the target.
 * unfortunatelly this will be tthe react script which is located in the head
 * to prevent mounting in the head we create a placeholder div with display:none where the script can inject it's content
 */

export function createPlaceholder(streamId) {
  if (document.getElementById('sqln-' + streamId)) return
  const placeholder = document.createElement('div')
  placeholder.id = 'sq-placeholder'
  placeholder.style.display = 'none'
  placeholder.innerHTML = `<div><div id="sqln-${streamId}"/></div></div>`
  document.body.appendChild(placeholder)
}

export function removePlaceholder() {
  const placeholder = document.getElementById('sq-placeholder')
  if (placeholder) placeholder.remove()
}
