import store from 'store'

export default function selectorCb<K extends keyof RootState, R>(
  reducerKey: K,
  selector: (state: RootState[K]) => R,
  cb: (result: R) => void
): () => void {
  let prevState: RootState[K]
  let prevResult: R

  const listener = () => {
    const state = store.getState()
    if (prevState === state[reducerKey]) return
    prevState = state[reducerKey]
    const result = selector(prevState)
    if (prevResult === result) return
    prevResult = result
    cb(result)
  }

  listener()

  return store.subscribe(listener)
}
