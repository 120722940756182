import toBase64 from 'utils/toBase64'
import { createOptimizedImage, transformExistingImgSrc } from 'utils/imgOptimization'
import * as t from './types'

export const createContext = async (
  props: t.UserConfig,
  { getGridContext }
): Promise<t.Context> => {
  const gridContext = getGridContext()
  let base64 = await toBase64(props.imgSrc)
  return { 
    base64: base64, 
    optImg: createOptimizedImage(props.imgSrc, gridContext, {
      fullWidth: true,
      scale: 1.5
    }) 
  }
}

export const versionUpdate = (props: t.Props) => {
  props = Object.assign({}, props)
  if (!props.__version) {
    props.__version = 1
    props.imgSrc = transformExistingImgSrc(props.imgSrc)
  }

  return props
}
