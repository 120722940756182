import * as React from 'react'
import styled from 'styled-components'
import Text from 'theme/organisms/Text'
import ImageGallery from 'theme/organisms/ImageGallery'
import { ms } from 'modules/browser/const'
import * as t from './types'

export default function TextWithImageGallery({
  textProps,
  imageGalleryProps,
  context,
  imagePosition,
  imageWidth,
  imageBreak,
}: t.Props) {
  return (
    <Wrapper className="TextWithImageGallery" {...{ imagePosition, imageWidth, imageBreak }}>
      <div className="gallery">
        <ImageGallery {...imageGalleryProps} context={context} __version={2}/>
      </div>
      <Text {...textProps} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > .gallery {
    width: 100%;
  }

  @media (min-width: ${(props) => ms[props.imageBreak]}px) {
    > .Text {
      blockquote {
        display: grid;
      }
      margin-top: -10px;
    }
    > .gallery {
      float: ${(props) => props.imagePosition};
      width: calc(${(props) => props.imageWidth || 0}% - 11px);
      ${(props) => (props.imagePosition === 'left' ? 'margin-right: 22px' : 'margin-left: 22px')};
    }
  }
`
