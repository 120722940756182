// import toBase64 from 'utils/toBase64'
// import { makeHtml } from 'utils/transformMarkdown'
// import batchFetch from 'utils/contentfulBatchFetch'
// import config from 'config'

export const createContext = async (props: any) => {
  return {
    dealExists: false,
    title: '',
    description: '',
    teaserImageUrl: '',
    clickoutUrl: {},
    merchantName: '',
    searchQueryStrings: [],
    products: [],
    showPagination: false,
    base64: '',
    totalPages: 0,
  }
  // const hits = await batchFetch('deal')
  // const deal: any = hits.find(hit => hit.objectID === props.dealId)

  // if (!deal || !props.dealId)
  //   return {
  //     dealExists: false,
  //     title: '',
  //     description: '',
  //     teaserImageUrl: '',
  //     clickoutUrl: {},
  //     merchantName: '',
  //     searchQueryStrings: [],
  //     products: [],
  //     showPagination: false,
  //     base64: '',
  //     totalPages: 0,
  //   }

  // const base64 = await toBase64(deal.teaserImageUrl)
  // const description = makeHtml(deal.description)
  // const qs = (deal.searchQueryStrings || '')
  //   .split(' ')
  //   .join('')
  //   .split('\n')
  //   .filter((_) => _)
  //   .map((q) => (q[0] === '#' ? q.replace('#', '') : q))
  // let products = []
  // let totalPages = 0

  // if (qs.length && (qs.length === 1 || qs.length === 3)) {
  //   const hpp = qs.length === 1 ? 3 : 1
  //   // @ts-ignore
  //   const states = qs.map((q) => createAlgoliaHelper.url.getStateFromQueryString(q))
  //   const helpers = states.map((state) => {
  //     const helper = createAlgoliaHelper(config.indexes.products, {
  //       disjunctiveFactes: ['gender'],
  //     })
  //     // @ts-ignore
  //     helper.setState(state)
  //     // @ts-ignore
  //     helper.setIndex(config.indexes.products)
  //     // @ts-ignore
  //     helper.setState(helper.state.addDisjunctiveFacet('gender'))
  //     helper.addDisjunctiveFacetRefinement('gender', 'women')
  //     helper.addDisjunctiveFacetRefinement('gender', 'unisex')
  //     return helper
  //   })
  //   const results = await Promise.all(
  //     helpers.map((helper) => helper.searchOnce({ hitsPerPage: hpp }))
  //   )
  //   // @ts-ignore
  //   products = [].concat(...results.map((r) => r.content.hits))
  //   // @ts-ignore
  //   totalPages = results.reduce((p, n) => (n.content.nbPages > p ? p : n.content.nbPages), 50)
  // }

  // let clickoutUrl: any = {
  //   to: deal.clickoutUrl,
  // }
  // if (deal.clickoutDataManualCpc) clickoutUrl['data-manual-cpc'] = deal.clickoutDataManualCpc
  // if (deal.clickoutDataE1) clickoutUrl['data-e1'] = deal.clickoutDataE1
  // if (deal.clickoutDataE2) clickoutUrl['data-e2'] = deal.clickoutDataE2
  // if (deal.clickoutDataE3) clickoutUrl['data-e3'] = deal.clickoutDataE3

  // return {
  //   dealExists: true,
  //   title: deal.title,
  //   description: description,
  //   teaserImageUrl: deal.teaserImageUrl,
  //   clickoutUrl: clickoutUrl,
  //   merchantName: deal.merchantName,
  //   searchQueryStrings: qs,
  //   showPagination: deal.showPagination,
  //   products: products,
  //   base64: base64,
  //   totalPages: totalPages,
  // }
}
