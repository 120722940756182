import toBase64 from 'utils/toBase64'
// import {useStorefinder} from 'modules/storefinder'

export async function createContext(props) {
  const base64Images = await Promise.all(props.images.map((img) => toBase64(img.src)))

  return { base64Images }
}

export function versionUpdate(props) {
  if (!props.__version) {
    let newProps = { title: props.title, gridArea: props.gridArea, images: [] }
    const n = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    n.forEach((n) =>
      newProps.images.push({
        // @ts-ignore
        src: props['image' + n],
        // @ts-ignore
        alt: props['alt' + n],
        // @ts-ignore
        link: props['link' + n],
      })
    )
    props = newProps
    props.__version = 1
  }

  return props
}

// export const createPartialStateUpdates = async (store, props) => useStorefinder.preload(store, props)
