import * as React from 'react'
import useWindowEvent from 'hooks/useWindowEvent'

let requested = false
let list: [HTMLElement, (...args: any[]) => void][] = []

let run: (fn: any) => void = () => null
try {
  // @ts-ignore
  run = window.requestIdleCallback || window.requestAnimationFrame
} catch (e) {} // eslint-disable-line no-empty

function calcWidth(el: HTMLElement | null, cb: (size: number) => void) {
  if (!el) return
  list.push([el, cb])
  if (!requested) run(calculate)
}

function calculate() {
  let widths = {}
  let i
  for (i = 0; i < list.length; i++) {
    widths[i] = list[i][0].offsetWidth
  }
  for (i = 0; i < list.length; i++) {
    const cb = list[i][1]
    cb(widths[i])
  }
  list = []
  requested = false
}

export default function useWidth(initialWidth = 0): [any, number] {
  const ref = React.useRef<HTMLElement | null>(null)
  const [width, setWidth] = React.useState(initialWidth)

  useWindowEvent(
    !!ref.current,
    'resize',
    React.useCallback(() => {
      calcWidth(ref.current, setWidth)
    }, []),
    { throttle: 100, initialCall: true }
  )

  return [ref, width]
}
