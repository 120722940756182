import * as t from './types'
// import * as sq from 'utils/squarelovin'

export const createContext = async (props: t.UserConfig): Promise<t.Context> => {
  // const entries = await sq.fetchEntries(0, props.tag)

  // const hits = entries.hits.map((entry) => ({
  //     id: entry.id,
  //     img: entry.media[0].cropped_url,
  //     userName: entry.user_name,
  //     postingTime: entry.posting_time,
  //   }))
  //   .slice(0, 30)

  return { hits:[] }
}
