import * as t from './types'
import preloadProducts from 'modules/products/preload'
import {FilterValues} from 'modules/products/types'

export const createContext = async (props:t.UserConfig) => {
  const tagGroups:Record<string, string[]> = {}
  const filterValues:Partial<FilterValues> = {
    tags: props.tags,
    dynamicFilters: {}
  }

  for(const row of props.tagGroups) {
    if(!tagGroups[row.id]) {
      tagGroups[row.id] = []
      if(filterValues.dynamicFilters)
        filterValues.dynamicFilters['tagGroups.'+row.id] = []
    }
    tagGroups[row.id].push(row.value)
    if(filterValues.dynamicFilters)
      filterValues.dynamicFilters['tagGroups.'+row.id].push(row.value)
  }

  return {
    dynamicFilters: Object.keys(tagGroups).map(key => ({
      label: key,
      path: 'tagGroups.' + key
    })),
    filterValues: filterValues
  }
}

export const versionUpdate = (props: any) => {
  props = Object.assign({}, props)

  /**
    Migration 21.09.2021: RLX-1962 add tag groups
   */
  if (!props.__version) {
    props.__version = 1
    props.tagGroups = []
  }

  return props
}

export const createPartialStateUpdates = async (store, props:t.Props) =>
  preloadProducts(
    'CCW-' + props.gridArea, 
    props.context.filterValues, 
    props.context.dynamicFilters
  )
