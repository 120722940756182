// @flow
import * as React from 'react'
import styled from 'styled-components'
import Text from 'theme/organisms/Text'
import Embedly from 'theme/organisms/Embedly'
import { ms } from 'modules/browser/const'

type Props = {
  imagePosition: 'left' | 'right'
  imageWidth: number | null
  imageBreak: 'LAPTOP' | 'TABLET'
  textProps: {
    children: string
    centered: boolean
    verticalCentered: boolean
    bordered: boolean
  }
  embedlyProps: {
    src: string
    placeholder: string
  }
}

export default function TextWithEmbedly({
  textProps,
  embedlyProps,
  imagePosition,
  imageWidth,
  imageBreak,
}: Props) {
  return (
    <Wrapper className="TextWithEmbedly" {...{ imagePosition, imageWidth, imageBreak }}>
      <div className="embedly">
        <Embedly {...embedlyProps} />
      </div>
      <Text {...textProps} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > .embedly {
    width: 100%;
  }

  @media (min-width: ${(props) => ms[props.imageBreak]}px) {
    > .Text {
      blockquote {
        display: grid;
      }
      margin-top: -10px;
    }
    > .embedly {
      float: ${(props) => props.imagePosition};
      width: calc(${(props) => props.imageWidth || 0}% - 11px);

      ${(props) => (props.imagePosition === 'left' ? 'margin-right: 22px' : 'margin-left: 22px')};
    }
  }
`
