import * as React from 'react'
import styled from 'styled-components'
import { Props } from './types'
import useInView from 'hooks/useInView'
import * as actions from './actions'
import {FiPlay} from 'react-icons/fi'
import useLazyImgSrc from 'hooks/useLazyImageSrc'

export default function Video(props: Props) {
  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)
  const [active, setActive] = React.useState(false)
  const video = React.useRef<HTMLVideoElement|null>(null)
  const [imgRef, src] = useLazyImgSrc(props.context.optImg, props.context.base64)

  React.useEffect(() => {
    if(!active || !video.current) return
    video.current.play()
  }, [active])


  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  if(props.url.includes('youtube.com/embed/')) return (
    <Wrapper ref={wrapperRef} className="Image" active={active} hasPreview={!!props.previewImg}>
      <div className="youtube">
        <iframe width="560" height="349" src={props.url}/>
      </div>
    </Wrapper>
  )

  return (
    <Wrapper ref={wrapperRef} className="Image" active={active} hasPreview={!!props.previewImg}>
      <video ref={video} controls src={props.url}/>
      {props.previewImg && (
        <>
          <img ref={imgRef} src={src} alt={props.previewImgAlt || ''}/>
          <div className='icon-wrapper' onClick={()=>setActive(true)}>
            <div className='border'>
              <FiPlay/>
            </div>
          </div>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;


  > .youtube {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  > video {
    width: 100%;
  }

  > img {
    display: ${props => props.active || !props.hasPreview ? 'none' : 'block'};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  > .icon-wrapper {
    display: ${props => props.active || !props.hasPreview ? 'none' : 'flex'};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    > .border {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: rgba(0,0,0,0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
        font-size: 40px;
        color: whitesmoke;
        margin-left: 5px;
      }

      &:hover {
        background: rgba(255,255,255,0.7);
        > svg {color: black;}
      }
    }
  }
`
