import preloadProducts from 'modules/products/preload'

export const createContext = async () => {
  // const createAlgoliaHelper = await fetchCreateAlgoliaHelper()
  // const helper = createAlgoliaHelper(config.indexes.navigation, {
  //   disjunctiveFacets: ['objectID'],
  //   attributesToRetrieve: ['productTags'],
  //   /**
  //    * when remove: create fn that fetches all categories from CF once
  //    * create dict by objectID 
  //    * resolve productTags (emmos like)
  //    */
  // })
  // helper.addDisjunctiveFacetRefinement('objectID', '5gvKdF9ic0I0IAksACQeeA')
  // const result = await helper.searchOnce()

  // const hit: any | null = result.content.hits[0]
  // const productTags = hit ? hit.productTags || [] : []

  // return {
  //   dynamicFilters: productTags.map((tag) => ({
  //     label: tag.group,
  //     path: 'tagGroups.' + tag.group,
  //   })),
  // }
  return {
    dynamicFilters: []
  }
}

export const preprocessProps = (props: any) => ({
  ...props,
  filters: {
    ...props.filters,
    price: props.price
      ? props.filters.price[0] === null && props.filters.price[1] === null
        ? null
        : props.filters.price
      : null,
  },
})

export const createPartialStateUpdates = async (store: any, props: any) =>
  preloadProducts('generic-product-list', props.filters)
