// @flow
import { dispatchEvent } from 'redux-ruleset'
import * as tracking from 'modules/tracking'

function pushToAnalytics(event: any) {
  dispatchEvent(tracking.sendEvent(event))
}

/**
handles all user interactions by sending them to gtm
- can send interactions
- set interaction cookies
- says if some interaction can be displayed
 */
let blacklist = new Set<any>([])
if (typeof window !== 'undefined') {
  blacklist = new Set<any>(document.cookie.match(/rlxia_[^=]+/g) || [])
}

function setCookie(key) {
  const exdays = 30
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = 'rlxia_' + key + '=true;' + expires + ';'
}

export default {
  sendInteraction(event: any) {
    if (!event['eventCategory']) throw new Error('interactions must have a "eventCategory"')
    if (!event['eventAction']) throw new Error('interactions must have a "eventAction"')
    if (!event['eventLabel']) throw new Error('interactions must have a "eventLabel"')
    pushToAnalytics(event)
    blacklist.add(event['eventAction'])
    setCookie(event['eventAction'])
  },
  allowAction(eventAction: any) {
    if (blacklist.size >= 10) return false
    return !blacklist.has('rlxia_' + eventAction)
  },
}
