import * as React from 'react'
import styled, { css } from 'styled-components'
import * as t from './types'
import Link from 'theme/atoms/Link'
import ArrowSvg from 'svg/Arrow'
import { colors } from './const'
import ty from 'utils/typographie'
import { ms } from 'modules/browser/const'
import useInView from 'hooks/useInView'
import * as actions from './actions'

export default function BannerText(props: t.Props) {
  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => props.eecTrack && actions.click(props)

  return (
    <Wrapper
      bg={props.background}
      big={props.big}
      to={props.link}
      ref={wrapperRef}
      onClick={() => handleClick()}
    >
      {props.title && <h4>{props.title}</h4>}
      {props.description && <p>{props.description}</p>}
      {props.buttonLabel && (
        <span className="btn">
          {props.buttonLabel}
        </span>
      )}
      {props.linkLabel && (
        <span className="link">
          {props.linkLabel} <ArrowSvg color={colors[props.background].text} />
        </span>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  background: ${(p) => colors[p.bg].bg};
  color: ${(p) => colors[p.bg].text};
  padding: ${(p) => (p.big ? '60px 25px' : '40px 25px')};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
      color: ${(p) => colors[p.bg].text};
    }

  > h4 {
    ${ty([26, 0.46, 34], 'Cormorant Garamond', '500')}
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  > p {
    ${ty([22, 0.84, 28], 'Cormorant Garamond', '500')}
    margin: 0;
    margin-bottom: 15px;
  }

  > a,
  span {
    display: flex;
    color: ${(p) => colors[p.bg].text};
    &:hover {
      color: ${(p) => colors[p.bg].text};
    }
    cursor: pointer;
  }

  > .link {
    white-space: nowrap;
    align-items: center;
    ${ty([16, 0.51, 20], 'Cormorant Garamond', 'bold', 'italic')}
    > svg {
      margin-left: 15px;
    }
  }

  > .btn {
    border: 1px solid ${(p) => colors[p.bg].text};
    ${ty([11, 2.4, 20], 'Raleway', 'bold')}
    padding: 10px 20px;
    text-transform: uppercase;
    white-space: nowrap;
    display: flex;
    justify-content: center;
  }

  @media (min-width: ${ms.LAPTOP}px) {
    ${(p) =>
      p.big ||
      css`
        padding: 35px 150px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      `}

    ${(p) =>
      p.big &&
      css`
        padding: 100px 80px;
        min-height: 550px;
        align-items: center;
        justify-content: center;
      `}
    
    > h4 {
      ${ty([36, 0.7, 48])}
      margin-bottom: ${(p) => (p.big ? '40px' : '0')};
    }

    > p {
      ${ty([28, 0.5, 34])}
      margin-bottom: ${(p) => (p.big ? '40px' : '0')};
    }

    > a {
      margin-left: ${(p) => p.big || '40px'};
    }

    > .link {
      ${ty([18, 0.34, 26])}
    }
  }
`
