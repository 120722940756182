// @flow
import * as React from 'react'
import interactor from '../utils/interactor'

type Event = {
  eventCategory: string
  eventAction: string
  eventLabel: string
}

export default function useInteraction(identifier: string) {
  const initiallyAllowed = interactor.allowAction(identifier)
  const [allowed, setAllowed] = React.useState(initiallyAllowed)

  const send = React.useCallback((evt: Event) => {
    interactor.sendInteraction(evt)
    setAllowed(false)
  }, [])
  return {
    allowed,
    send,
    initiallyAllowed,
    className: 'rlxia_' + identifier,
  }
}
