import * as t from './types'
import toBase64 from 'utils/toBase64'
import { createOptimizedImage } from 'utils/imgOptimization'

export const createContext = async (
  props: t.UserConfig,
  { getGridContext }
): Promise<t.Context> => {
  const gridContext = getGridContext()
  let base64 = await toBase64(props.src)
  return { base64, optImg: createOptimizedImage(props.src, gridContext) }
}
