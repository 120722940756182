import * as React from 'react'
import styled from 'styled-components'

type Props = {
  bigSpace?: boolean
}

export default function Divider(props: Props) {
  return (
    <Wrapper className="Divider" big={props.bigSpace}>
      <div />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 760px;
  height: 1px;
  background: #262626;
  margin: 7px auto;

  ${(props) => props.big && `margin-top: 30px;`}

  > div {
    position: absolute;
    top: -7px;
    left: calc(50% - 7px);
    height: 15px;
    width: 15px;
    background: #262626;
    border-radius: 15px;
  }
`
