// @flow
import * as React from 'react'
import { useInitializer } from 'modules/products'
import ProductListing from 'theme/molecules/ProductListing'

type Props = {
  categoryId: string
  showSizeFilter: boolean
  context: {
    showFavouriteFilter: boolean
    category: string
    dynamicFilters?: {
      label: string
      path: string
    }[]
  }
}

export default function FashionProductList(props: Props) {
  const identifier = 'fashion-product-list'
  const { category, dynamicFilters } = props.context
  useInitializer(identifier, { category }, { dynamicFilters })

  return (
    <ProductListing
      categoryLinks
      showSizeFilter={props.showSizeFilter}
      identifier={identifier}
      listName="cms-fashion-product-list"
      showFavouriteFilter={props.context.showFavouriteFilter}
    />
  )
}
