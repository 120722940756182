// import * as React from 'react'
import styled from 'styled-components'
import * as t from './types'
import { ms } from 'modules/browser/const'
// import LazyImg from 'theme/atoms/LazyImg'
// import { useSquarelovinId } from 'modules/ui'
// import useEntries from './useEntries'

const GRAY_PIXEL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM8EQcAAfQBKC0E6zcAAAAASUVORK5CYII='

export default function SquarelovinFull(props: t.Props) {
  return null
  // const sq = useSquarelovinId()
  // const entries = useEntries(props.context.hits, props.context.nbPages, props.tag)
  // const hasNextPage = entries.page < entries.nbPages

  // const handleEntryClick = (entry: t.Entry) => {
  //   sq.set(entry.id)
  // }

  // return (
  //   <Wrapper className="SquarelovinFull" data-cy-ctx="organisms/SquarelovinFull">
  //     <Images>
  //       {entries.hits.map((row, i) => (
  //         <div
  //           className="wrapper"
  //           key={i}
  //           data-cy-handle="entry"
  //           onClick={() => handleEntryClick(row)}
  //           style={{
  //             animationDelay: (i % 30) * 0.05 + 's',
  //           }}
  //         >
  //           <LazyImg
  //             alt={'Post von ' + row.userName}
  //             src={row.img}
  //             default={GRAY_PIXEL}
  //             offset={500}
  //           />
  //         </div>
  //       ))}
  //     </Images>
  //     {hasNextPage && (
  //       <button
  //         className="loadMore"
  //         data-cy-handle="load-more-btn"
  //         data-cy-state="has-next-page"
  //         onClick={() => entries.setPage(entries.page+1)}
  //       >
  //         mehr
  //       </button>
  //     )}
  //     {/* {entry && (
  //       <Modal entry={entry} onClose={() => setEntry(null)}/>
  //     )} */}
  //   </Wrapper>
  // )
}

const Wrapper = styled.div`
  > .loadMore {
    color: #ffffff;
    font-family: Raleway;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 2.36px;
    line-height: 25px;
    background: #555555;
    text-transform: uppercase;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 8px;
    border: none;
    width: 100%;
    display: block;
    cursor: pointer;
    max-width: 400px;
    text-align: center;
  }
`

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  margin-bottom: 5px;

  > .wrapper {
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    > img {
      width: 100%;
    }
    animation-duration: 1.5s;
    animation-name: slidein;
    animation-fill-mode: forwards;
    @keyframes slidein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  @media (min-width: ${ms.TABLET}px) and (max-width: ${ms.LAPTOP_L - 1}px) {
    grid-template-columns: repeat(3, 1fr);

    .wrapper:nth-child(6n-5) {
      grid-column: 1 / span 2;
      grid-row-start: span 2;
    }
    .wrapper:nth-child(6n-1) {
      grid-column: 2 / span 2;
      grid-row-start: span 2;
    }
  }

  @media (min-width: ${ms.LAPTOP_L}px) {
    grid-template-columns: repeat(4, 1fr);

    .wrapper:nth-child(10n-9) {
      grid-column: 1 / span 2;
      grid-row-start: span 2;
    }
    .wrapper:nth-child(10n-2) {
      grid-column: 3 / span 2;
      grid-row-start: span 2;
    }
  }
`
