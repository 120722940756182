import toBase64 from 'utils/toBase64'
import contentfulBatchFetch from 'utils/contentfulBatchFetch'

type MagazineArticle = {
  objectID: string
  teaserImageUrl?: string
}

type Props = {
  magazineArticleId: string
}

export const createContext = async (props: Props) => {
  const hits: MagazineArticle[] = await contentfulBatchFetch('magazineArticle')
  const article = hits.find((hit) => hit.objectID === props.magazineArticleId)

  if (!article || !article.teaserImageUrl) return { article: null, base64: '' }

  const base64 = await toBase64(article.teaserImageUrl)

  return { article, base64 }
}

// export const versionUpdate = props => {
//   if(!props.__version){
//     props = {...props, __version: 1}
//   }
//   if(props.__version === 1){
//     props = {
//       __version: 2,
//       gridArea: props.gridArea,
//       magazineArticleId: props.id
//     }
//   }
//   return props
// }
