import useLazyImageSrc from 'hooks/useLazyImageSrc'
import * as React from 'react'
import styled, { css } from 'styled-components'
import * as t from './types'
import { colors } from './const'
import ty from 'utils/typographie'
import ArrowSvg from 'svg/Arrow'
import Link from 'theme/atoms/Link'
import { ms } from 'modules/browser/const'
import useInView from 'hooks/useInView'
import * as actions from './actions'

export default function CategoryBanner(props: t.Props) {
  const [ref, image] = useLazyImageSrc(
    props.context.optImg,
    props.context.base64,
    200,
  )
  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => props.eecTrack && actions.click(props)

  return (
    <Wrapper
      ref={wrapperRef}
      onClick={handleClick}
      textPosition={props.textPosition}
      bg={colors[props.backgroundColor]}
      to={props.link}
    >
      <div className="overlay" />
      <div className="text">
        <div className="h-wrapper">
          <h3>{props.title}</h3>
        </div>
        <p>{props.subtitle}</p>
        <span>
          {props.linktext} <ArrowSvg />
        </span>
      </div>
      <div className="img-wrapper">
        <img ref={ref} src={image} alt={props.alt} />
      </div>
    </Wrapper>
  )
}

const isLeft = (p) => p.textPosition === 'top-left' || p.textPosition === 'bottom-left'
const isTop = (p) => p.textPosition === 'top-left' || p.textPosition === 'top-right'

const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  > .overlay {
    position: absolute;
    width: 200%;
    height: 360px;
    @media (min-width: ${ms.LAPTOP_L}px) {
      height: 520px;
    }
    background: ${(p) => p.bg};
    border-radius: 48%;
    ${(p) => {
      switch (p.textPosition) {
        case 'top-left':
          return css`
            top: 0;
            left: 0;
            transform: translate(-50%, -50%);
          `
        case 'top-right':
          return css`
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
          `
        case 'bottom-left':
          return css`
            bottom: 0;
            left: 0;
            transform: translate(-50%, 50%);
          `
        case 'bottom-right':
          return css`
            bottom: 0;
            right: 0;
            transform: translate(50%, 50%);
          `
      }
    }}
  }

  > .text {
    color: #262626;
    z-index: 1;
    order: ${(p) => (isTop(p) ? 0 : 1)};
    text-align: ${(p) => (isLeft(p) ? 'left' : 'right')};
    padding: ${(p) => (isTop(p) ? '15px 15px 10px 15px' : '10px 15px 10px 15px')};

    @media (min-width: ${ms.LAPTOP_L}px) {
      padding: ${(p) => (isTop(p) ? '30px 40px 10px 40px' : '10px 40px 30px 40px')};
    }

    > .h-wrapper {
      height: 34px;
      margin-top: 5px;
      width: 95%;
      position: relative;
      margin-left: ${(p) => (isLeft(p) ? '0' : '15%')};
      @media (min-width: ${ms.LAPTOP_L}px) {
        height: 46px;
      }
      > h3 {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        ${ty([26, 0.46, 34], 'Cormorant Garamond', '500')}
        @media (min-width: ${ms.LAPTOP_L}px) {
          ${ty([32, 0.62, 46])}
        }
      }
    }


    > p {
      height: 40px;
      margin-bottom: 10px;
      width: 85%;
      margin-left: ${(p) => (isLeft(p) ? '0' : '15%')};
      ${ty([13, 0.39, 20], 'Open Sans')}
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      @media (min-width: ${ms.LAPTOP_L}px) {
        ${ty([18, 0.54, 24])}
        height: 48px;
        margin-bottom: 15px;
      }
    }

    > span {
      height: 20px;
      color: #262626;
      &:hover {
        color: #262626;
      }
      ${ty([16, 0.51, 20], 'Cormorant Garamond', 'bold', 'italic')}
      @media (min-width: ${ms.LAPTOP_L}px) {
        ${ty([18, 0.34, 26])}
        height: 26px;
      }
      text-decoration: none;
      > svg {
        margin-left: 10px;
      }
    }
  }

  > .img-wrapper {
    margin: ${(p) => (isLeft(p) ? '0 0 0 15px' : '0 15px 0 0')};
    @media (min-width: ${ms.LAPTOP_L}px) {
      margin: ${(p) => (isLeft(p) ? '0 0 0 40px' : '0 40px 0 0')};
    }
    > img {
      width: 100%;
      display: block;
    }
  }
`
