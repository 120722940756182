// @flow

export function createResizer(el: any) {
  const iWindow = el.contentWindow
  let scriptObj = iWindow.document.createElement('script')
  scriptObj.type = 'text/javascript'
  scriptObj.id = 'resize-updater'
  scriptObj.innerHTML = `
      ;(${handleDOMResize.toString()})()
      ;(${handleBottomScroll.toString()})()
  `
  iWindow.document.body.appendChild(scriptObj)
}

function handleDOMResize() {
  // @ts-ignore
  let attachEvent = document.attachEvent
  let isIE = navigator.userAgent.match(/Trident/)
  let requestFrame = (function() {
    // @ts-ignore
    let raf =
      window.requestAnimationFrame ||
      // @ts-ignore
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      function(fn) {
        return window.setTimeout(fn, 20)
      }
    return function(fn) {
      return raf(fn)
    }
  })()

  let cancelFrame = (function() {
    // @ts-ignore
    let cancel =
      window.cancelAnimationFrame ||
      // @ts-ignore
      window.mozCancelAnimationFrame ||
      window.webkitCancelAnimationFrame ||
      window.clearTimeout
    return function(id) {
      return cancel(id)
    }
  })()

  function resizeListener(e) {
    let win = e.target || e.srcElement
    if (win.__resizeRAF__) cancelFrame(win.__resizeRAF__)
    win.__resizeRAF__ = requestFrame(function() {
      let trigger = win.__resizeTrigger__
      trigger.__resizeListeners__.forEach(function(fn) {
        fn.call(trigger, e)
      })
    })
  }

  function objectLoad() {
    // @ts-ignore
    this.contentDocument.defaultView.__resizeTrigger__ = this.__resizeElement__
    // @ts-ignore
    this.contentDocument.defaultView.addEventListener('resize', resizeListener)
  }

  // @ts-ignore
  window.addResizeListener = function(element, fn) {
    if (!element.__resizeListeners__) {
      element.__resizeListeners__ = []
      if (attachEvent) {
        element.__resizeTrigger__ = element
        element.attachEvent('onresize', resizeListener)
      } else {
        if (getComputedStyle(element).position === 'static') element.style.position = 'relative'
        let obj = (element.__resizeTrigger__ = document.createElement('object'))
        obj.setAttribute(
          'style',
          'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden; pointer-events: none; z-index: -1;'
        )
        // @ts-ignore
        obj.__resizeElement__ = element
        obj.onload = objectLoad
        obj.type = 'text/html'
        if (isIE) element.appendChild(obj)
        obj.data = 'about:blank'
        if (!isIE) element.appendChild(obj)
      }
    }
    element.__resizeListeners__.push(fn)
  }

  let h = 0
  function push() {
    // $FlowFixMe
    let newH = document.body.scrollHeight
    if (h === newH) {
      return
    } else {
      h = newH
    }
    window.parent.postMessage(['setHeight', h], '*')
  }
  push()
  // @ts-ignore
  window.addResizeListener(document.body, push)
}

function handleBottomScroll() {
  let scrollAnchor = document.createElement('div')
  scrollAnchor.id = 'scroll-anchor'
  // $FlowFixMe
  document.body.appendChild(scrollAnchor)
  let observer = new IntersectionObserver(function() {
    window.dispatchEvent(new Event('scroll'))
  })
  observer.observe(scrollAnchor)
}
