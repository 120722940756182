import toBase64 from 'utils/toBase64'
import { createOptimizedImage, transformExistingImgSrc } from 'utils/imgOptimization'
import { makeHtml } from 'utils/transformMarkdown'
import * as t from './types'

export const createContext = async (
  props: t.UserConfig,
  { getGridContext }
): Promise<t.Context> => {
  const gridContext = getGridContext()
  let base64 = await toBase64(props.src)
  return { base64: base64, optImg: createOptimizedImage(props.src, gridContext) }
}

export const preprocessProps = (props: t.Props) => {
  return Object.assign({}, props, {
    label: makeHtml(props.label),
  })
}

export const versionUpdate = (props: t.Props) => {
  /**
      Migration 01.05.2021: Version 2 - transform old ImgSources for Image optimization
    */
  props = Object.assign({}, props)
  if (!props.__version) {
    props.__version = 1
    props.src = transformExistingImgSrc(props.src)
  }
  return props
}
