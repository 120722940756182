import * as React from 'react'
import styled from 'styled-components'
import useLazyImageSrc from 'hooks/useLazyImageSrc'
import Link from 'theme/atoms/Link'
import * as t from './types'
import useInView from 'hooks/useInView'
import * as actions from './actions'
import ty from 'utils/typographie'
import { ms } from 'modules/browser/const'
import { colors } from './const'

let isAdmin = false
if (typeof window !== 'undefined') {
  // @ts-ignore
  isAdmin = window.document.isStorybook || false
}

export default function ImageWithLinksBanner(props: t.Props) {
  const [ref, image] = useLazyImageSrc(
    props.context.optImg,
    props.context.base64,
    100,
  )
  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => props.eecTrack && actions.click(props)

  return (
    <Wrapper
      ref={wrapperRef}
      className="ImageWithLinks"
      onClick={handleClick}
      backgroundColor={props.backgroundColor}
    >
      <div className="banner"></div>
      <Label title={props.title} subtitle={props.subtitle}>
        <div className="content">
          <div className="title">{props.title}</div>
          <div className="subtitle" dangerouslySetInnerHTML={{ __html: props.subtitle }} />
        </div>
        <div className="img-wrapper">
          <img ref={ref} src={image} alt={props.alt} />
          {props.links.map((link) => (
            <AbsoluteLink key={link.to} {...link} />
          ))}
        </div>
      </Label>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;

  > .banner {
    background: ${(props) => colors[props.backgroundColor]};
    position: absolute;
    width: 100%;
    height: 28%;
    top: 0px;
    z-index: 2;
    border-bottom-right-radius: 50% 100%;
    border-bottom-left-radius: 50% 100%;

    @media (min-width: ${ms.MOBILE_L}px) {
      height: 25%;
    }

    @media (min-width: 440px) {
      height: 23%;
    }

    @media (min-width: ${ms.TABLET}px) {
      height: 20%;
    }

    @media (min-width: 650px) {
      height: 18%;
    }

    @media (min-width: ${ms.LAPTOP}px) {
      height: 23%;
    }

    @media (min-width: 830px) {
      height: 21%;
    }

    @media (min-width: ${ms.LAPTOP_L}px) {
      height: 24%;
    }

    @media (min-width: ${ms.LAPTOP_XL}px) {
      height: 23%;
    }
  }
`

const AbsoluteLink = styled(Link).attrs((props) => ({
  style: {
    top: props.y / 10 + '%',
    left: props.x / 10 + '%',
    width: props.w / 10 + '%',
    height: props.h / 10 + '%',
  },
}))`
  background: ${isAdmin ? 'rgba(0,0,0, 0.5)' : 'none'};
  position: absolute;
  z-index: 5;
`

const Label = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;

  > .content {
    z-index: 3;
    text-align: center;
    margin-top: 10px;
    position: relative;

    @media (min-width: ${ms.MOBILE_L}px) {
      margin-top: 20px;
    }

    > .title {
      width: 90%;
      margin: 0 auto;
      > p {
        color: #262626;
      }
      ${ty([26, 0.46, 34], 'Cormorant Garamond', '500')}

      @media (min-width: ${ms.MOBILE_L}px) {
        margin-bottom: 10px;
      }

      @media (min-width: ${ms.LAPTOP_L}px) {
        ${ty([36, 0.7, 48], 'Cormorant Garamond', '500')}
      }
    }
    > .subtitle {
      margin: 0 auto;
      width: 80%;
      min-height: 60px;
      ${ty([13, 0.39, 20], 'Open Sans')}

      @media (min-width: ${ms.LAPTOP_L}px) {
        ${ty([18, 0.54, 24], 'Open Sans')}
        min-height: 72px;
      }

      > p {
        color: #262626;
        margin: 0px;
      }
    }
  }

  > .img-wrapper {
    position: relative;
    z-index: 1;
    > img {
      width: 100%;
    }
  }

`
