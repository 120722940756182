import { createClient, ContentfulClientApi } from 'contentful'
import config from '../../src/config'
import fetch from './universal-fetch'

let cache: Record<string, Promise<any[]> | undefined> = {}

const typeMappers = {
  deal: 'deal',
  navigation: '-',
  magazineArticle: '3Zh1r1lnGomcuEGiAUmGgi',
  retailStore: 'retailStore',
  author: 'author'
}

export default async function contentfulBatchFetch(
  contentType: 'deal' | 'navigation' | 'magazineArticle' | 'retailStore' | 'author'
) {
  if (cache[contentType]) return cache[contentType] as Promise<any[]>
  const client = createClient({
    space: 'qrma0jgw0rvs',
    environment: 'master',
    accessToken: 'ad7f0dc479378057e3ce854049ea8660c102a1fd6ad3e6599817eefa29b26c70',
  })

  if (contentType === 'navigation') {
    cache[contentType] = fetchCategories(client)
  } else {
    cache[contentType] = getBufferedEntries(client, {
      content_type: typeMappers[contentType],
      limit: 1000,
      bufferSize: 150,
      include: 0,
    })
  }

  if(contentType === 'magazineArticle') {
    const result = await cache[contentType]
    for(const article of result as any[]) {
      article.relatedMagazineArticles = []
    }
  }

  return cache[contentType] as Promise<any[]>
}

async function getBufferedEntries(client: ContentfulClientApi, config: any) {
  const { bufferSize, ...contentfulConfig } = config
  if (!bufferSize) throw new Error('bufferSize is required')
  const buffer: any[] = []
  let i = 0
  while (i * bufferSize < config.limit) {
    const entries = await client
      .getEntries<any>({
        ...contentfulConfig,
        limit: bufferSize,
        skip: i * bufferSize,
      })
      .then((response) =>
        response.items.map((row) => {
          delete row.fields.story
          return ({
            ...row.fields,
            objectID: row.sys.id,
            createdAt: row.sys.createdAt,
            updatedAt: row.sys.updatedAt,
          })
        })
      )

    buffer.push(...entries)
    if (entries.length < bufferSize) break
    i++
  }
  return buffer.slice(0, config.limit)
}

function createRawCategories(navItems, navigation) {
  let navigationDict = {}
  for (let nav of navigation) navigationDict[nav.objectID] = nav
  let dictByParentId = {}
  for (let item of navItems)
    if (item.parentId) {
      if (!dictByParentId[item.parentId]) dictByParentId[item.parentId] = []
      dictByParentId[item.parentId].push(item.id)
    }

  return navItems.map((item) => {
    const context = navigationDict[item.id]
    let meta: any = {}

    meta.childIds = dictByParentId[item.id] || []

    return Object.assign({}, context, item, meta)
  })
}

async function fetchCategories(client: ContentfulClientApi) {
  const data = await fetch(config.apiEndpoints.initialNavigation).then((res) => res.json())
  const allEntries = await Promise.all([
    getBufferedEntries(client, {
      content_type: 'hgNgk36gCIE8K0USACYGa', // lv1
      limit: 1000,
      bufferSize: 150,
      include: 0,
    }),
    getBufferedEntries(client, {
      content_type: '1pJW8MRE76yQKa6EoU8Q4m', // lv2
      limit: 1000,
      bufferSize: 150,
      include: 1,
    }),
    getBufferedEntries(client, {
      content_type: '4SGQIeJJF6UuQmUcC2mUK0', // lv3
      limit: 1000,
      bufferSize: 150,
      include: 0,
    }),
    getBufferedEntries(client, {
      content_type: 'level4ProductCategory', // lv4
      limit: 1000,
      bufferSize: 150,
      include: 0,
    }),
  ])

  const tagsById = await createTags(client)

  

  const entries: any[] = []
  for (const list of allEntries) entries.push(...list)

  const navItems = data
    .find((row) => row.templateOptions.dropdownItems)
    .templateOptions.dropdownItems.concat([
      {
        id: '5gvKdF9ic0I0IAksACQeeA',
        label: 'Bekleidung',
        link: '/shop/bekleidung',
        lv0: 'Bekleidung',
        lv1: null,
        lv2: null,
        lv3: null,
        weight: null,
        displayInMenu: false,
        searchQueryString: null,
        subNavTemplate: null,
        parentId: null,
        templateOptions: {},
      },
    ])
  return createRawCategories(navItems, entries).map((cat) => {
    cat.useStory = Boolean(cat.useStory)
    cat.useEnhancedStory = Boolean(cat.useEnhancedStory)
    cat.hideProducts = Boolean(cat.hideProducts)
    cat.categoryLevel = cat.categoryLevel || null
    cat.story = cat.story || null
    cat.productTags = cat.productTags || []
    cat.featuredInDropdownMenu = Boolean(cat.featuredInDropdownMenu)
    cat.metaRobotsNoindex = Boolean(cat.metaRobotsNoindex)
    const tags = new Set<string>()
    for(const tag of cat.productTags || []) {
      const hit = tagsById[tag.sys.id]
      if(hit) tags.add(hit.group)
    }
    cat.productTags = Array.from(tags).map(name => ({group:name}))

    delete cat.assignedLevel1Category
    delete cat.assignedLevel2Category
    delete cat.assignedLevel3Category
    delete cat.relatedCategories
    return cat
  })
}


async function createTags (client: ContentfulClientApi) {
  const tags = await getBufferedEntries(client, {
    content_type: 'productTag',
    limit: 2000,
    bufferSize: 1000,
    include: 1
  })

  const byId = {}
  for(const tag of tags) {
    if(tag && tag.tagGroups && tag.tagGroups[0]) byId[tag.objectID] = {
      group: tag.tagGroups[0].fields.name,
      tag: tag.tag,
      name: tag.name
    }
  }

  return byId
}