// @flow
import * as React from 'react'
import * as utils from './utils'
import useWindowEvent from 'hooks/useWindowEvent'

type Props = {
  gridArea: string
  content: string
}

export default function IFrame(props: Props) {
  const ref = React.useRef(null)
  const [src, setSrc] = React.useState('')

  useWindowEvent(
    true,
    'message',
    React.useCallback((e) => {
      if (!Array.isArray(e.data)) return
      let [type, iframeHeight] = e.data
      if (type === 'setHeight' && ref.current) {
        // @ts-ignore
        ref.current.height = iframeHeight + 'px'
      }
    }, [])
  )

  React.useEffect(() => {
    const shims =
      '<script src="https://polyfill.io/v2/polyfill.min.js?features=IntersectionObserver"></script>'
    const src = URL.createObjectURL(
      new Blob([(shims + props.content).replace(/#!s#/g, '</script>')], { type: 'text/html' })
    )
    setSrc(src)
  }, [props.content])

  return (
    <div className="Iframe">
      <iframe
        ref={ref}
        src={src}
        title={props.gridArea}
        width="100%"
        scrolling="yes"
        frameBorder="0"
        onLoad={() => utils.createResizer(ref.current)}
      />
    </div>
  )
}
