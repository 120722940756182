// @flow
import * as React from 'react'
import styled from 'styled-components'
import { useInteraction } from 'modules/user-interactions'
import { FaCheckCircle } from 'react-icons/fa'
import { Props } from './types'
import * as actions from './actions'
import useInView from 'hooks/useInView'

export const colors = {
  BrandColor: 'rgba(153, 52, 82, 0.8)',
  Orange: 'rgba(240, 168, 126, 0.8)',
  Braun: 'rgba(170, 146, 120, 0.8)',
  Beige: 'rgba(199, 182, 164, 0.8)',
  Dunkelgrau: 'rgba(109, 110, 113, 0.8)',
  Grau: 'rgba(167, 169, 172, 0.8)',
  Hellgrau: 'rgba(238, 236, 237, 0.8)',
  Hellgruen: 'rgba(213, 227, 131, 0.8)',
  Mint: 'rgba(114, 200, 182, 0.8)',
  Blau: 'rgba(134, 212, 232, 0.8)',
}

export default function SimpleQuestion(props: Props) {
  const interaction = useInteraction(props.eventAction)
  const [ref, inView] = useInView()
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      actions.scrollIntoView(props)
    }
  }, [inView, props])

  if (props.eventAction.match(/ |;/)) {
    // eslint-disable-next-line react/no-unescaped-entities
    // @ts-ignore
    if (window.document.isStorybook)
      // eslint-disable-next-line react/no-unescaped-entities
      return <h1 className="SimpleQuestion">No " " or ";" allowed in event-action</h1>
    return null
  }

  if (!interaction.initiallyAllowed) return null

  const handleClick = (opt: string) => () => {
    actions.click(props)
    interaction.send({
      // @ts-ignore
      event: 'triggeredGenericEvent',
      eventCategory: props.eventCategory,
      eventAction: props.eventAction,
      eventLabel: opt,
      eventQuestion: props.question,
    })
  }

  return (
    <Wrapper
      ref={ref}
      className={'SimpleQuestion rlxia_' + props.eventAction}
      action={props.eventAction}
      buttonBg={props.buttonBg}
    >
      <h3>{interaction.allowed ? props.question : 'Vielen Dank für dein Feedback'}</h3>
      {interaction.allowed && (
        <div className="options">
          <button onClick={handleClick(props.option1)}>{props.option1}</button>
          <button onClick={handleClick(props.option2)}>{props.option2}</button>
        </div>
      )}
      {interaction.allowed || (
        <div className="check-wrapper">
          <FaCheckCircle className="icon" />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  body.${(props) => props.action} & {
    display: none;
  }
  width: 100%;
  border: 1px solid #b9b4b4;
  border-radius: 5px;
  padding: 8px;

  > h3 {
    text-align: center;
    font-weight: normal;
  }

  > .options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;

    > button {
      display: block;
      width: 100%;
      padding: 8px;
      border: none;
      font-size: 16px;
      background: ${(props) => colors[props.buttonBg]};
      &:focus {
        outline: none;
      }
      cursor: pointer;
    }
  }

  > .check-wrapper {
    display: flex;
    justify-content: center;

    > .icon {
      color: green;
      font-size: 28px;
    }
  }
`
