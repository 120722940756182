import useLazyImageSrc from 'hooks/useLazyImageSrc'
import * as React from 'react'
import styled, { css } from 'styled-components'
import * as t from './types'
import ty from 'utils/typographie'
import ArrowSvg from 'svg/Arrow'
import Link from 'theme/atoms/Link'
import { ms } from 'modules/browser/const'
import useInView from 'hooks/useInView'
import * as actions from './actions'

export default function CategoryBannerBorderHorizontal(props: t.Props) {
  const [ref, image] = useLazyImageSrc(
    props.context.optImg,
    props.context.base64,
    100,
  )
  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => props.eecTrack && actions.click(props)

  return (
    <Wrapper ref={wrapperRef} onClick={handleClick} to={props.link}>
      <Label
        overlayPosition={props.overlayPosition}
        image={image}
        overlayPositionTopBottom={props.overlayPositionTopBottom}
        xPositionFocus={props.xPositionFocus}
        yPositionFocus={props.yPositionFocus}
      >
        <div className="background-image" ref={ref}>
          <span></span>
        </div>
        <div className="content">
          <h3 className="title">{props.title}</h3>
          <div className="subtitle" dangerouslySetInnerHTML={{ __html: props.subtitle }} />
          <span>
            {props.linktext} <ArrowSvg />
          </span>
        </div>
      </Label>
      <div className="border"></div>
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  position: relative;
  border: 1.35px solid #eeeced;
  text-decoration: none;
  display: block;

  @media (min-width: ${ms.LAPTOP}px) {
    margin-right: 0px;
  }

  > .border {
    position: absolute;
    top: 0%;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    border: 1.35px solid #555555;
    margin: 15px;

    @media (min-width: ${ms.TABLET}px) {
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      margin: 20px;
    }
  }
`

const Label = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 1fr;
  width: 100%;

  @media (min-width: ${ms.TABLET}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  > .background-image {
    background-image: url(${(props) => props.image});
    background-position-x: ${(props) => props.xPositionMobile}%;
    background-position-y: ${(props) => props.yPositionMobile}%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;

    @media (min-width: ${ms.TABLET}px) {
      background-position-x: ${(props) => props.xPositionTablet}%;
      background-position-y: ${(props) => props.yPositionTablet}%;
      ${(props) =>
        props.overlayPosition === 'left'
          ? css`
              order: 0;
            `
          : css`
              order: 2;
            `};
    }

    @media (min-width: ${ms.LAPTOP_L}px) {
      background-position-x: ${(props) => props.xPositionDesktop}%;
      background-position-y: ${(props) => props.yPositionDesktop}%;
    }

    @media (max-width: ${ms.TABLET}px) {
      ${(props) =>
        props.overlayPositionTopBottom === 'top'
          ? css`
              order: 0;
            `
          : css`
              order: 2;
            `};
    }
  }

  > .content {
    z-index: 3;
    margin-left: 30px;
    margin-right: 30px;
    order: 1;

    @media (max-width: ${ms.TABLET}px) {
      ${(props) =>
        props.overlayPositionTopBottom === 'top'
          ? css`
              margin-top: 20px;
              margin-bottom: 15px;
              padding-bottom: 35px;
            `
          : css`
              margin-top: 15px;
              margin-bottom: 20px;
              padding-top: 20px;
            `};
    }

    @media (min-width: ${ms.TABLET}px) {
      margin-top: 40px;
      margin-bottom: 30px;
      ${(props) =>
        props.overlayPosition === 'left'
          ? css`
              padding-bottom: 20px;
            `
          : css`
              padding-left: 10px;
            `};
    }

    @media (min-width: ${ms.LAPTOP}px) {
      margin-top: 50px;
      margin-bottom: 40px;
      ${(props) =>
        props.overlayPosition === 'left'
          ? css`
              padding-left: 10px;
              padding-right: 20px;
            `
          : css`
              padding-left: 20px;
            `};
    }

    @media (min-width: ${ms.LAPTOP_L}px) {
      margin-top: 70px;
      margin-bottom: 70px;
      margin-right: 20px;
      margin-left: 0px;
      ${(props) =>
        props.overlayPosition === 'left'
          ? css`
              padding-left: 40px;
              padding-right: 40px;
            `
          : css`
              padding-left: 20px;
            `};
    }

    > .title {
      margin: 0;
      margin-bottom: 10px;
      ${ty([22, 0.84, 28], 'Cormorant Garamond', '500')}
      max-height: 56px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      color: #555;

      @media (min-width: ${ms.LAPTOP_L}px) {
        ${ty([26, 0.46, 36], 'Cormorant Garamond', '500')}
        max-height: 68px;
        margin-bottom: 15px;
      }
    }

    > .subtitle {
      margin-bottom: 10px;
      min-height: 60px;
      height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      > p {
        margin: 0px;
        color: #262626;
        ${ty([14, 0.42, 20], 'Open Sans')}

        @media (min-width: ${ms.LAPTOP_L}px) {
          margin-bottom: 35px;
          ${ty([16, 0.48, 24])}
        }
      }

      @media (min-width: ${ms.LAPTOP_L}px) {
        margin-bottom: 35px;
        height: 72px;
      }
    }

    > span {
      ${ty([16, 0.51, 20], 'Cormorant Garamond', 'normal', 'italic')}
      text-decoration: none;
      color: #262626;

      @media (min-width: ${ms.LAPTOP_L}px) {
        ${ty([18, 0.34, 26], 'Cormorant Garamond')}
      }

      > svg {
        margin-left: 14px;
      }
    }
    a:visited {
      color: #262626;
    }
  }
`
