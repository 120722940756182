import './rules'
import { addRule } from 'redux-ruleset'
import store from 'store'
import reducer from './reducer'
import * as a from './actions'
import * as at from './const'
import * as t from './types'
import { State } from './reducer'

store.injectReducer(store, 'products', reducer)
let i = 0

declare global {
  interface RootState {
    products: State
  }
  interface RootReducers {
    products: typeof reducer
  }
  interface ModuleActions {
    products: a.Action
  }
}

export default function preloadProducts(
  identifier: string,
  filterValues: Partial<t.FilterValues>,
  dynamicFilters?: t.DynamicFilterDefinition[]
) {
  const fakeIdentifier = 'preload-product-list-' + i++
  const action = a.init(fakeIdentifier, filterValues, null, dynamicFilters)
  store.dispatch(action)
  // return []
  return new Promise((resolve) => {
    const timeout = setTimeout(() => {
      console.error('reached timeout for', fakeIdentifier, JSON.stringify(filterValues))
      resolve([])
    }, 15000)
    addRule({
      id: 'useInitializer-' + i,
      target: [at.FETCH_SUCCESS, at.FETCH_FAILURE],
      output: '#hydrate',
      addOnce: true,
      condition: (action: any) => action.meta.identifier === fakeIdentifier,
      consequence: (action, { getState }: any) => {
        clearTimeout(timeout)
        const state = getState()
        if (action.type === at.FETCH_FAILURE) {
          console.error('ERROR: could not fetch products', action.payload)
          resolve([])
        } else
          resolve([
            {
              path: ['products', identifier],
              state: state.products[fakeIdentifier],
            },
          ])
      },
    })
  })
}
