import * as React from 'react'
import {OptImg} from 'utils/imgOptimization'
import useLazyImageSrc from 'hooks/useLazyImageSrc'

type Props = {
  src: string | OptImg
  base64?: string
  offset?:number
  alt: string
}

export default function Image (props:Props) {
  const [ref, img] = useLazyImageSrc(props.src, props.base64, props.offset)
  return (
    <img alt={props.alt} src={img} ref={ref}/>
  )
}