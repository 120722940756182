/* eslint-disable @kaminrunde/firescout/onclick-needs-handle */
import styled from 'styled-components'
import * as React from 'react'
import * as t from './types'
import Link from 'theme/atoms/Link'
import ArrowSvg from 'svg/Arrow'
import ty from 'utils/typographie'
import useLazyImageSrc from 'hooks/useLazyImageSrc'
import { ms } from 'modules/browser/const'
import useInView from 'hooks/useInView'
import * as actions from './actions'

export default function Suggestion(props: t.Props) {
  const [ref, image] = useLazyImageSrc(props.imgSrc, props.context.imgBase64, 200)
  const [authorRef, authorSrc] = useLazyImageSrc(
    props.context.authorImgUrl,
    props.context.authorImgBase64,
    200
  )
  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => props.eecTrack && actions.click(props)

  return (
    <Wrapper to={props.link} ref={wrapperRef} onClick={handleClick}>
      <div className="intro">
        {props.context.authorName ? props.context.authorName + ' empfiehlt' : 'Unsere Empfehlung'}
      </div>

      <div className="img-wrapper">
        <img className="main" ref={ref} src={image} alt={props.title} />
        <img className="author" ref={authorRef} src={authorSrc} alt="Author" />
      </div>

      <h4>{props.title}</h4>

      <p>{props.description}</p>

      <span>
        hier entdecken <ArrowSvg />
      </span>
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  border: 1px solid black;
  padding: 20px 30px 50px 30px;

  @media (min-width: ${ms.LAPTOP_L}px) {
    padding: 40px 60px 50px 60px;
  }

  > .intro {
    ${ty([13, 2.84, 20], 'Raleway')};
    color: #262626;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 45px;

    @media (min-width: ${ms.LAPTOP_L}px) {
      ${ty([14, 3.05, 20], 'Raleway')};
    }
  }

  > .img-wrapper {
    margin: 0 30px;
    position: relative;
    margin-bottom: 20px;
    > .main {
      width: 100%;
    }
    > .author {
      position: absolute;
      border-radius: 100%;
      width: 60px;
      top: -30px;
      left: calc(50% - 30px);
      border: 3px solid white;
      @media (min-width: ${ms.LAPTOP_L}px) {
        width: 80px;
        top: -40px;
        left: calc(50% - 40px);
      }
    }
  }

  > h4 {
    ${ty([26, 0.46, 36], 'Cormorant Garamond', '500')}
    color: #262626;
    text-align: center;
    margin-bottom: 10px;
    left: calc(50% - 30px);
    @media (min-width: ${ms.LAPTOP_L}px) {
      ${ty([36, 0.7, 48])}
      margin-bottom: 15px;
    }
  }

  > p {
    margin: 0;
    color: #262626;
    ${ty([13, 0.39, 20], 'Open Sans')}
    margin-bottom: 15px;
    @media (min-width: ${ms.LAPTOP_L}px) {
      ${ty([18, 0.54, 24])}
      margin-bottom: 25px;
    }
  }

  > span {
    ${ty([18, 0.34, 26], 'Cormorant Garamond', 'normal', 'italic')}
    color: #262626;
    text-align: center;
    display: block;
    > svg {
      margin-left: 5px;
    }

    &:hover {
      color: #262626;
    }
  }
`
