export const colors = {
  Rosa: {
    bg: 'rgba(153, 52, 82)',
    text: '#FFFFFF',
  },
  Braun: {
    bg: '#AF9174',
    text: '#FFFFFF',
  },
  Hellgrau: {
    bg: '#EEECED',
    text: '#262626',
  },
  Dunkelgrau: {
    bg: '#A7A9AC',
    text: '#262626',
  },
  Hellbraun: {
    bg: '#CAB6A2',
    text: '#262626',
  },
}
