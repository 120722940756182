import * as React from 'react'
import store from 'store'
import { firescoutMockFn } from '@kaminrunde/cypress-firescout'

type Props = {
  name: string
  cProps: any
  events: any[]
}

export default function MoleculeTest({ name, cProps, events }: Props) {
  if (process.env.NODE_ENV === 'development') {
    const Component = React.useMemo(() => {
      return React.lazy(() => import(`../../molecules/${name}`))
    }, [name])

    const parsedProps = React.useMemo(() => {
      let props = {}
      for (let key in cProps) {
        if (typeof cProps[key] === 'string' && cProps[key].startsWith('fn:')) {
          const fn = cProps[key].replace('fn:', '')
          props[key] = new Function(`return ${fn}`)
        } else props[key] = cProps[key]
      }
      return props
    }, [])

    const [props, setProps] = React.useState(parsedProps)

    const finalProps = React.useMemo(() => {
      let finalProps = {}
      for (let key in props) {
        if (typeof props[key] === 'function') {
          finalProps[key] = firescoutMockFn(`${name}.${key}`, props[key]()(props, setProps))
        } else finalProps[key] = props[key]
      }
      return finalProps
    }, [props])

    React.useEffect(() => {
      events.forEach(store.dispatch)
    }, [])

    return (
      <React.Suspense fallback="loading...">
        <Component {...finalProps} />
      </React.Suspense>
    )
  } else {
    return null
  }
}
