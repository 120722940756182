import * as React from 'react'
import styled from 'styled-components'
import useLazyImageSrc from 'hooks/useLazyImageSrc'
import Link from 'theme/atoms/Link'
import { Props } from './types'
import useInView from 'hooks/useInView'
import * as actions from './actions'
import ty from 'utils/typographie'
import ArrowSvg from 'svg/Arrow'
import { ms } from 'modules/browser/const'

let isAdmin = false
if (typeof window !== 'undefined') {
  // @ts-ignore
  isAdmin = window.document.isStorybook || false
}

export default function Figurtypen(props: Props) {
  const [ref, image] = useLazyImageSrc(
    props.context.optImg,
    props.context.base64,
    100,
  )
  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => props.eecTrack && actions.click(props)

  return (
    <Wrapper ref={wrapperRef} className="Figurtypen" onClick={handleClick}>
      <div className="border">
        <h3 className="title">{props.title}</h3>
        <div className="image-wrapper">
          <img ref={ref} src={image} alt={props.alt} />
          {props.links.map((link) => (
            <AbsoluteLink key={link.to} {...link} />
          ))}
        </div>
        <Link className="content-wrapper" onClick={handleClick} to={props.link}>
          <h4 className="subtitle">{props.subtitle}</h4>
          <div className="link">
            {props.linktext} <ArrowSvg />
          </div>
        </Link>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > .border {
    border: 1.35px solid #555555;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 30px;

    @media (min-width: ${ms.TABLET}px) {
        padding: 30px 35px 30px 36px;
      }

    @media (min-width: ${ms.LAPTOP}px) {
        padding: 10px 150px 10px 150px;
    }
    
    @media (min-width: ${ms.LAPTOP_L}px) {
        padding: 10px 250px 10px 250px;
      }

    > .title {
      padding: 32px 15px 20px 16px;
      margin: 0;
      color: #262626;
      text-align: center;
      ${ty([26, 0.46, 36], 'Cormorant Garamond', '500')}

      @media (min-width: ${ms.TABLET}px) {
        padding: 0px 35px 30px 36px;
      }

      @media (min-width: ${ms.LAPTOP}px) {
        padding: 15px 35px 15px 36px;
        ${ty([32, 0.62, 46], 'Cormorant Garamond', '500')}
      }
    }

    > .image-wrapper {
      width: 100%;
      position: relative;
      > img {
        width: 100%;
        height: auto;
        display: block
      }
    }

    > .content-wrapper {
      background-color: #eeeced;
      color: #262626;
      text-align: center;
      margin: 30px 15px 10px 15px;

      @media (min-width: ${ms.TABLET}px) {
        display: flex;
        justify-content: center;
        padding: 15px 40px 13px 40px;
      }

      @media (min-width: ${ms.LAPTOP}px) {
        margin: 40px 15px 60px 15px;
      }

      @media (min-width: ${ms.LAPTOP_L}px) {
        margin: 40px 15px 60px 15px;
      }

      @media (min-width: ${ms.LAPTOP_XL}px) {
        margin: 40px 15px 60px 15px;
      }

      > .subtitle {
        background-color: #eeeced;
        padding: 20px 16px 20px 17px;
        margin-bottom: 0px;
        ${ty([16, 0.51, 22], 'Cormorant Garamond')}

        @media (min-width: ${ms.TABLET}px) {
          padding: 0;
          margin: 0px 50px 0px 0px;
        }

        @media (min-width: ${ms.LAPTOP}px) {
          ${ty([18, 0.34, 26], 'Cormorant Garamond', '500')}
        }
      }

      > .link {
        background-color: #eeeced;
        padding: 0px 78px 20px 76px;
        ${ty([16, 0.34, 20], 'Cormorant Garamond', 'bold', 'italic')}
        text-decoration: none;
        cursor: pointer;
        
        a:visited {
        color: #262626;
        }

        @media (min-width: ${ms.TABLET}px) {
          padding: 0;
          margin: 0;
        }


        @media (min-width: ${ms.LAPTOP}px) {
          ${ty([18, 0.34, 26], 'Cormorant Garamond', 'bold', 'italic')}
        }

        > svg {
          margin-left: 10px;
        }
      }
    }
  }
`

const AbsoluteLink = styled(Link).attrs((props) => ({
  style: {
    top: props.y / 10 + '%',
    left: props.x / 10 + '%',
    width: props.w / 10 + '%',
    height: props.h / 10 + '%',
  },
}))`
  background: ${isAdmin ? 'rgba(0,0,0, 0.5)' : 'none'};
  position: absolute;
`
