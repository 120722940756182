import * as React from 'react'
import * as t from './types'
import styled from 'styled-components'
import useLazyImageSrc from 'hooks/useLazyImageSrc'
import { colors, positions } from './const'
import useWidth from './hooks/useWidth'
import Link from 'theme/atoms/Link'
import * as actions from './actions'
import useInView from 'hooks/useInView'

export default function Banner(props: t.Props) {
  const [ref, image] = useLazyImageSrc(
    props.context.optImg,
    props.context.base64,
    100,
  )
  const [widthRef, width] = useWidth()
  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)

  const labelProps = {
    background: colors[props.overlayColor],
    overlayPosition: positions[props.overlayPosition],
    titleRows: props.context.titleRows,
    subtitleRows: props.context.subtitleRows,
    subtitle: props.subtitle,
    titleSize: width / props.ratio,
    subtitleSize: width / props.ratioSubtitle,
  }

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      actions.scrollIntoView(props)
    }
  }, [inView, props])

  return (
    <Wrapper
      data-cy-ctx="organisms/Banner"
      ref={wrapperRef}
      className="Banner"
      to={props.link}
      data-cy-handle="wrapper"
      onClick={() => actions.click(props)}
    >
      <div ref={widthRef} className="content">
        <img ref={ref} src={image} alt={props.alt} />
        <Label {...labelProps}>
          <div className="title" dangerouslySetInnerHTML={{ __html: props.title }} />
          <div className="subtitle" dangerouslySetInnerHTML={{ __html: props.subtitle }} />
        </Label>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  > .content {
    position: relative;
    cursor: pointer;
    > img {
      width: 100%;
    }

    p {
      margin: 1em 0px;
      opacity: 0.9;
      line-height: normal;
    }
  }
`

const Label = styled.div`
  position: absolute;
  background: ${(props) => props.background};
  bottom: 0;

  text-align: center;
  color: white;

  > .title p {
    margin: 0px;
  }
  > .subtitle p {
    margin: 0;
  }

  /*SQUARE*/
  ${(props) =>
    props.overlayPosition === 'bottom' &&
    `
      right: 5.5%;
      height: 30%;
      bottom: 7%;
      left: 5.5%;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 5px;
  `}

  /*REACTANGLE OVERLAY*/
  ${(props) =>
    props.overlayPosition === 'left' &&
    `
      width: 48.42%;
      left: 2.6%;
      bottom: 8%;
      top: 5.5%;
  `}

  ${(props) =>
    props.overlayPosition === 'right' &&
    `
      width: 48.42%;
      right: 2.6%;
      top: 5.5%;
      bottom: 8%;
  `}

  /*REACTANGLE LINES POSITIONS*/
  ${(props) =>
    (props.overlayPosition === 'left' || props.overlayPosition === 'right') &&
    `
      display: flex;
      justify-content: center;
      flex-direction:column; 

  `}

  ${(props) =>
    (props.overlayPosition === 'left' || props.overlayPosition === 'right') &&
    props.titleRows === 2 &&
    !props.subtitle &&
    `
      .title p:first-child {
          margin: 0;
      }
      .title p:last-child {
          margin-top:0;
      }
      `}

  ${(props) =>
    (props.overlayPosition === 'left' || props.overlayPosition === 'right') &&
    props.titleRows === 1 &&
    props.subtitleRows === 2 &&
    `
      .title p {
          margin-bottom: 0;
      }
      
      .subtitle p:first-child {
          margin-top: 0;
          margin-bottom: 0;
      }
      .subtitle p:last-child {
          margin-top:0;
      }
      `}

  ${(props) =>
    (props.overlayPosition === 'left' || props.overlayPosition === 'right') &&
    props.titleRows === 1 &&
    props.subtitleRows === 2 &&
    `
      .title p {
          margin-bottom: 0;
      }
      
      .subtitle p:first-child {
          margin-top: 0;
          margin-bottom: 0;
      }
      .subtitle p:last-child {
          margin-top:0;
      }
      `} 

  ${(props) =>
    (props.overlayPosition === 'left' || props.overlayPosition === 'right') &&
    props.titleRows === 2 &&
    props.subtitleRows === 1 &&
    `          
      .title p:first-child {
          margin-bottom: 0;
      }
      .title p:last-child {
          margin-top:0;
          margin-bottom: 0;
      }
      .subtitle {
          padding-bottom: 30px;
      }
      .subtitle p {
          margin-top: 0;
      }
      `} 

  ${(props) =>
    (props.overlayPosition === 'left' || props.overlayPosition === 'right') &&
    props.titleRows === 2 &&
    props.subtitleRows === 2 &&
    `
      .title p:first-child {
          margin-bottom: 0;
      }
      .title p:last-child {
          margin-top: 0;
          margin-bottom: 0;
      }
      .subtitle p:first-child {
          margin-top: 0;
          margin-bottom: 0;
      }
      .subtitle p:last-child {
          margin-top:0;
      }
      `} 

  > .title {
    font-weight: bolder;
    font-size: ${(props) => props.titleSize}px;
  }

  > .subtitle {
    font-size: ${(props) => props.subtitleSize}px;
    font-weight: 100;
    line-height: normal;
    margin-top: ${(props) => props.subtitleSize / 2}px;
  }
`
