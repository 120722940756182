// @flow
import * as React from 'react'
import { useInitializer } from 'modules/products'
import ProductListing from 'theme/molecules/ProductListing'

type Props = {
  showSizeFilter: boolean
  context: {
    dynamicFilters?: {
      label: string
      path: string
    }[]
  }
  filters: {
    page: number
    query: string
    tags: string[]
    color: string[]
    brand: string[]
    size: string[]
    shop: string[]
    category: string
    price: any //null | [number,number],
    objectIds: string[]
    wunderCategories: string[]
  }
}

export default function ProductList(props: Props) {
  const identifier = 'generic-product-list'
  useInitializer(identifier, props.filters, { dynamicFilters: props.context.dynamicFilters })
  return (
    <ProductListing
      showSizeFilter={props.showSizeFilter}
      identifier={identifier}
      listName="product-list"
      // productTags={props.productTags}
    />
  )
}
