import encodeUrl from 'encodeurl'

let dirCheck = false

/* eslint-disable */

var tryRequire = function tryRequire(id) {
  let result
  try {
    result = require(`${id}`)
  } catch (e) {
    result = null
  }

  return result
}
/* eslint-enable */

export default async function toBase64(url: string, size = 16): Promise<string> {
  // called from storybook
  if (typeof window !== 'undefined') return url

  const fs = tryRequire('fs')
  const crypto = tryRequire('crypto')
  const path = tryRequire('path')
  const fetch = tryRequire('node-fetch')
  const sharp = tryRequire('sharp')

  if (!url) {
    console.log('(toBase64) src is undefined')
    return ''
  }

  if (url.endsWith('.mp4')) {
    return ''
  }

  if (!dirCheck) {
    let dir = path.resolve(__dirname, '../../.base64')
    await new Promise((resolve) => {
      fs.stat(dir, (err: any) => {
        if (err) fs.mkdir(dir, () => resolve(null))
        else resolve(null)
      })
    })
    dirCheck = true
  }

  const cacheName = crypto
    .createHash(`md5`)
    .update(url)
    .digest(`hex`)
  const cachePath = path.resolve(__dirname, '../../.base64', cacheName)

  const cache: string | null = await new Promise((resolve) => {
    fs.readFile(cachePath, (err, data) => {
      if (err) resolve(null)
      // not found
      else resolve(data.toString())
    })
  })

  if (cache) return cache

  try {
    let formattedUrl = encodeUrl(url.replace(/\n| /g, ''))
    const img = await fetch(formattedUrl).then((res) => res.buffer())
    const resizedImageBuf = await sharp(img)
      .resize(undefined, size)
      .toBuffer()

    const data = `data:image/png;base64,${resizedImageBuf.toString('base64')}`

    await new Promise((resolve) => fs.writeFile(cachePath, data, () => resolve(null)))

    return data
  } catch (e) {
    console.log(`ERROR (toBase64): "${url}"`)
    return url
  }
}
