import config from 'config'
import preloadProducts from 'modules/products/preload'
import batchFetch from 'utils/contentfulBatchFetch'

export const createContext = async (props: any) => {
  if (!config.categories[props.categoryId]) {
    return {
      showFavouriteFilter: false,
      category: '',
      dynamicFilters: [],
    }
  }
  const path: string = config.categories[props.categoryId].categoryPath

  let showFavouriteFilter = false
  if (path === '') showFavouriteFilter = true
  if (path === 'Blusen & Tuniken') showFavouriteFilter = true

  const hits = await batchFetch('navigation')
  const hit: any | null = hits.find(hit => hit.objectID === props.categoryId)
  const productTags = hit ? hit.productTags || [] : []

  return {
    showFavouriteFilter,
    category: path,
    dynamicFilters: productTags.map((tag) => ({
      label: tag.group,
      path: 'tagGroups.' + tag.group,
    })),
  }
  // return {
  //   showFavouriteFilter,
  //   category: path,
  //   dynamicFilters: [],
  // }
}

export const createPartialStateUpdates = async (store: any, props: any) =>
  preloadProducts(
    'fashion-product-list',
    { category: props.context.category },
    props.context.dynamicFilters
  )

export const versionUpdate = (props: any) => {
  props = Object.assign({}, props)

  /**
    Migration 26.03.2020: RLX-1564 added size filter ability
   */
  if (!props.__version) {
    props.__version = 1
    props.showSizeFilter = false
  }

  return props
}
