import React, { useEffect, useState } from 'react'
import * as t from './types'
import styled from 'styled-components'
import ty from 'utils/typographie'
import { ms } from 'modules/browser/const'
import { useSubscription } from 'modules/newsletter'
import { colors } from './const'
import * as actions from './actions'

export default function Newsletter(props: t.Props) {
  return null
  // const [emailError, setEmailError] = useState(false)
  // const [nameError, setNameError] = useState(false)
  // const newsletter = useSubscription()

  // const handleClick = () => props.eecTrack && actions.click(props)

  // useEffect(() => {
  //   newsletter.checkSubscription()
  // }, [])

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   if (newsletter.email.trim() !== '' && newsletter.name.trim() !== '') {
  //     const formInput = {
  //       email: newsletter.email,
  //       name: newsletter.name,
  //       listId: props.listId || '',
  //       subscriptionIntent: props.subscriptionIntent,
  //     }
  //     newsletter.subscribe(formInput)
  //   } else {
  //     if (newsletter.email.trim() === '') {
  //       setEmailError(true)
  //     }
  //     if (newsletter.name.trim() === '') {
  //       setNameError(true)
  //     }
  //   }
  // }

  // if (newsletter.isSubscribed)
  //   return (
  //     <Wrapper
  //       data-cy-ctx="organisms/Newsletter"
  //       data-cy-state="successfully-subscribed"
  //       backgroundColor={props.backgroundColor}
  //     >
  //       <div className="content">
  //         <h4 className="subscribedTitle">{props.subscribedTitle}</h4>
  //       </div>
  //     </Wrapper>
  //   )

  // if (newsletter.pendingSubscription)
  //   return (
  //     <Wrapper
  //       data-cy-ctx="organisms/Newsletter"
  //       data-cy-state="successfully-subscribed"
  //       backgroundColor={props.backgroundColor}
  //     >
  //       <div className="content">
  //         <h4 className="subscribedTitle">
  //           Wunderbar! Klicke nur noch den Link in der Mail und die Anmeldung ist abgeschlossen
  //         </h4>
  //       </div>
  //     </Wrapper>
  //   )

  // if (newsletter.isFetching)
  //   return (
  //     <Wrapper
  //       data-cy-ctx="organisms/Newsletter"
  //       data-cy-state="fetching-newsletter-api"
  //       backgroundColor={props.backgroundColor}
  //     />
  //   )

  // return (
  //   <Wrapper
  //     backgroundColor={props.backgroundColor}
  //     data-cy-ctx="organisms/Newsletter"
  //     data-cy-state="unsubscribed"
  //   >
  //     <div className="content">
  //       <h3>{props.title}</h3>
  //       <form className="newsletter_sign_up_form" onSubmit={handleSubmit} id="email_signup">
  //         <Input
  //           className="input"
  //           placeholder={'Deine E-Mail-Adresse'}
  //           onChange={(e) => {
  //             newsletter.setEmail(e.target.value)
  //           }}
  //           value={newsletter.email}
  //           error={emailError}
  //           data-cy-handle="email-input"
  //         />
  //         <Input
  //           className="input"
  //           placeholder={'Dein Vorname'}
  //           onChange={(e) => {
  //             newsletter.setName(e.target.value)
  //           }}
  //           value={newsletter.name}
  //           error={nameError}
  //           data-cy-handle="name-input"
  //         />
  //         <button className="unsubscribedButton" data-cy-handle="subscribe" onClick={handleClick}>
  //           Anmelden
  //         </button>
  //         <div
  //           className="unsubscribedSubtitle"
  //           dangerouslySetInnerHTML={{ __html: props.unsubscribedSubTitle }}
  //         />
  //       </form>
  //     </div>
  //   </Wrapper>
  // )
}

const Wrapper = styled.div`
  background-color: ${(props) => colors[props.backgroundColor].bg};
  > .content {
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    @media (min-width: ${ms.TABLET}px) {
      padding: 60px 50px;
    }

    > h3 {
      ${ty([28, 0.5, 34], 'Cormorant Garamond', '500')}
      color: white;
      margin-bottom: 20px;
    }

    > .subscribedTitle {
      ${ty([26, 0.46, 36], 'Cormorant Garamond', '500')}
      color: ${(props) => colors[props.backgroundColor].text};
      text-align: center;

      @media (min-width: ${ms.LAPTOP_L}px) {
        ${ty([32, 0.62, 46], 'Cormorant Garamond', '500')}
      }
    }

    > .newsletter_sign_up_form {
      > .unsubscribedTitle {
        ${ty([26, 0.46, 36], 'Cormorant Garamond', '500')}
        color: ${(props) => colors[props.backgroundColor].text};
        margin-bottom: 20px;

        @media (min-width: ${ms.LAPTOP_L}px) {
          ${ty([32, 0.62, 46], 'Cormorant Garamond', '500')}
        }
      }

      > input {
        ${ty([14, 0.42, 24], 'Open Sans')}
        width: 100%;
        border-style: none;
        display: block;
        margin-bottom: 15px;
        padding: 15px 20px;
        &::placeholder {
          color: ${(props) => colors[props.backgroundColor].bg};
        }
      }

      > .unsubscribedButton {
        ${ty([14, 3.05, 20], 'Raleway', 'bold')};
        text-transform: uppercase;
        margin-bottom: 15px;
        border: solid 1px ${(props) => colors[props.backgroundColor].text};
        color: ${(props) => colors[props.backgroundColor].text};
        width: 100%;
        text-align: center;
        padding: 15px;
        background: ${(props) => colors[props.backgroundColor].bg};
        cursor: pointer;
        outline: none;
      }

      > .unsubscribedSubtitle {
        ${ty([11, 0.33, 16], 'Open Sans')}
        color: ${(props) => colors[props.backgroundColor].text};
        > p {
        color: ${(props) => colors[props.backgroundColor].text};
          > a {
            text-decoration: underline;
            color: ${(props) => colors[props.backgroundColor].text};
          }
        }
      }
    }
  }
`

const Input = styled.input.attrs((props) => ({
  type: 'text',
  style: {
    outline: props.error ? '3px solid #ff0000' : 'none',
  },
}))``
