export const colors = {
  'Wucu Red': {
    bg: '#993452',
    text: 'white',
  },
  Gray: {
    bg: '#EEECED',
    text: '#262626',
  },
  Gold: {
    bg: '#AA9278',
    text: 'white',
  },
}
