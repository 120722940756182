import 'intersection-observer'
import * as React from 'react'
import styled from 'styled-components'
import { createPlaceholder, removePlaceholder } from './placeholder'
import createScript from './script'
import { monkeypatchSquarelovinListeners } from './monkeypatchings'
import { useInView } from 'react-intersection-observer'
import * as t from './types'
import * as actions from './actions'

const streamId = '592441e63ee073370139abdc'
const types = {
  TEASER: 'teaser',
  MAIN: 'main',
}

export default function Squarelovin(props: t.Props) {
  // if(teaser) return null
  const [ref, inView]: any = useInView({
    triggerOnce: true,
    rootMargin: `200px 0px 200px 0px`,
  })
  const wasInView = React.useRef(false)

  const handleClick = () => props.eecTrack && actions.click(props)

  React.useEffect(() => {
    if (!inView) return
    const type = props.teaser ? types.TEASER : types.MAIN
    removePlaceholder()
    const unlisten = monkeypatchSquarelovinListeners()
    createScript(streamId, type)
    return () => {
      createPlaceholder(streamId)
      unlisten()
    }
  }, [props.teaser, inView])

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  return (
    <Wrapper className="Squarelovin" isTeaser={props.teaser} onClick={handleClick}>
      <div ref={ref}>
        <div id={'sqln-' + streamId} />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  line-height: 1.58;
  max-width: calc(100vw - 30px);
  min-height: ${(props) => (props.isTeaser ? 350 : 800)}px;
  > div {
    width: 100%;
  }
`
