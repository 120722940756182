import * as t from './types'
// import * as sq^uarelovin from 'utils/squarelovin'

export async function createContext(props): Promise<t.Context> {
  // const entries = await squarelovin.fetchEntries(0, props.tag)
  // return {
  //   hits: entries.hits.map((e) => ({
  //     id: e.id,
  //     img: e.media[0].cropped_url,
  //     productIds: e.product_ids,
  //     userName: e.user_name,
  //     userProfileLink: e.user_profile,
  //     postingTime: e.posting_time,
  //   })),
  //   nbPages: entries.nbPages
  // }
  return {
    hits: [],
    nbPages: 0
  }
}
