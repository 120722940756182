import * as React from 'react'
import styled from 'styled-components'
import Link from 'theme/atoms/Link'
import ty from 'utils/typographie'
import { ms } from 'modules/browser/const'
import * as t from './types'
import useInView from 'hooks/useInView'
import * as actions from './actions'

export default function Sizebar(props: t.Props) {
  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => props.eecTrack && actions.click(props)

  return (
    <Wrapper
      className="full-width-MOBILE full-width-MOBILE_L full-width-TABLET full-width-LAPTOP"
      ref={wrapperRef}
      onClick={handleClick}
    >
      <div className="scroll">
        <div className="title">Größe</div>
        <ul>
          {props.sizes.map((size) => (
            <li key={size.label}>
              <Link to={size.link}>
                <div className="text">{size.label}</div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #eeeced;
  padding-left: 15px;
  position: relative;
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  > .scroll {
    display: flex;
    align-items: center;
    height: 76px;

    @media (min-width: ${ms.LAPTOP_L}px) {
      height: 100px;
    }

    @media (min-width: 1400px) {
      justify-content: center;
    }

    > .title {
      text-transform: uppercase;
      ${ty([13, 2.84, 20], 'Raleway')};
      color: #262626;
      margin-right: 15px;

      @media (min-width: ${ms.LAPTOP}px) {
        ${ty([14, 3.05, 20], 'Raleway')};
        margin-right: 40px;
      }
    }
    > ul {
      list-style-type: none;
      display: flex;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-left: 0px;
      > li {
        > span,
        a {
          display: flex;
          background-color: #ffffff;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          color: #262626;
          margin-right: 12px;

          @media (min-width: ${ms.LAPTOP}px) {
            height: 52px;
            width: 52px;
            margin-right: 21px;
          }

          > .text {
            ${ty([14, 1.23, 24], 'Raleway')};

            @media (min-width: ${ms.LAPTOP}px) {
              ${ty([18, 0.9, 32], 'Raleway')};
            }
          }
        }
        > span,
        a:hover {
          background-color: #eeeced;
          border: 2px solid #ffffff;
        }
      }
    }
  }
`
