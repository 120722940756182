/**
 * The Squarelovin Widget expects to be rendered in an MPA. Therefore it does not care about unlistening wenn it unmounts.
 * Unfortunatelly it also has no oportunity to remove the listeners manually.
 * So we we must monkeypath the attachment of the listeners to correctly unlisten all listeners
 */

const isSquarelovinScript = (script) => script && script.src && script.src.match(/squarelovin/)

export function monkeypatchSquarelovinListeners() {
  var _listeners = []
  var _timeouts = []

  const doc = window.document // prerender has problems to reference document within 'addEventListener'

  EventTarget.prototype.addEventListenerBase = EventTarget.prototype.addEventListener
  EventTarget.prototype.addEventListener = function(type, listener, ...rest) {
    if (isSquarelovinScript(doc.currentScript)) {
      _listeners.push({ target: this, type: type, listener: listener })
    }
    return this.addEventListenerBase(type, listener, ...rest)
  }

  const oldTimeout = window.setTimeout
  window.setTimeout = function(...args) {
    const key = oldTimeout(...args)
    if (isSquarelovinScript(doc.currentScript)) {
      _timeouts.push(key)
    }
    return key
  }

  return () => {
    _listeners.forEach(({ target, type, listener }) => target.removeEventListener(type, listener))
    _timeouts.forEach((key) => window.clearTimeout(key))
    EventTarget.prototype.addEventListener = EventTarget.prototype.addEventListenerBase
    window.setTimeout = oldTimeout
    window.onscroll = null
    ;[]
      .concat(
        Array.from(document.querySelectorAll('link[href*=squarelovin]')),
        Array.from(document.querySelectorAll('script[src*=squarelovin]'))
      )
      .forEach((el) => el.remove())
  }
}
