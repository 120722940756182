import * as React from 'react'
import { useInitializer } from 'modules/products'
import ProductListing from 'theme/molecules/ProductListing'
import * as t from './types'


export default function CurratedCategoryWidget(props: t.Props) {
  const identifier = 'CCW-' + props.gridArea
  useInitializer(
    identifier, 
    props.context.filterValues, 
    {
      dynamicFilters: props.context.dynamicFilters
    }
  )

  return <ProductListing identifier={identifier} listName="cms-currated-category-widget" />
}
