export default function debounce(func: (...args: any[]) => void, wait = 1, immediate = false) {
  let timeout
  return function() {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let context = this,
      // eslint-disable-next-line prefer-rest-params
      args: any = arguments
    let later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    let callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}
