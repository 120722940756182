import { createSelector } from 'reselect'

const sortSizeOptions = createSelector(
  (sizes: any) => sizes,
  (_, transform: (...args: any[]) => any) => transform,
  (sizes, transform) => {
    // $FlowFixMe
    transform = transform || ((val) => val)
    // order A-E sizes
    if (sizes.every((size) => transform(size).match(/^[0-9]+[A-Z]$/))) {
      sizes = sizes.sort((a, b) => {
        a = parseInt(transform(a).replace(/A-Z/, ''), 10)
        b = parseInt(transform(b).replace(/A-Z/, ''), 10)
        return a < b ? -1 : 0
      })
    }
    // order numeric: +40, 40, 40-42, 40/42
    else if (sizes.every((size) => transform(size).match(/^[+]?[0-9]+(\/|-)?[0-9]*$/))) {
      sizes = sizes.sort((a, b) => {
        a = parseInt(
          transform(a)
            .replace('+', '')
            .replace(/(\/|-).*/, ''),
          10
        )
        b = parseInt(
          transform(b)
            .replace('+', '')
            .replace(/(\/|-).*/, ''),
          10
        )
        return a < b ? -1 : 0
      })
    }
    // order Char-Sizes: XS,S,M,L,XL,XXL,XXXL,XXXXL
    else if (
      sizes.every((size) =>
        transform(size).match(/^XS$|^S$|^M$|^L$|^XL$|^XXL$|^XXXL$|^XXXXL$|^2XL|^3XL|^4XL$$$/)
      )
    ) {
      const dict = sizes.reduce((p, n) => (p[transform(n)] = n) && p, {})
      let newSizes: any[] = []
      if (dict['XS']) newSizes.push(dict['XS'])
      if (dict['S']) newSizes.push(dict['S'])
      if (dict['M']) newSizes.push(dict['M'])
      if (dict['L']) newSizes.push(dict['L'])
      if (dict['XL']) newSizes.push(dict['XL'])
      if (dict['XXL']) newSizes.push(dict['XXL'])
      if (dict['2XL']) newSizes.push(dict['2XL'])
      if (dict['XXXL']) newSizes.push(dict['XXXL'])
      if (dict['3XL']) newSizes.push(dict['3XL'])
      if (dict['XXXXL']) newSizes.push(dict['XXXXL'])
      if (dict['4XL']) newSizes.push(dict['4XL'])
      sizes = newSizes
    }
    return sizes
  }
)

export default sortSizeOptions
