// @flow

export const colors = {
  BrandColor: 'rgba(153, 52, 82, 0.8)',
  Orange: 'rgba(240, 168, 126, 0.8)',
  Braun: 'rgba(170, 146, 120, 0.8)',
  Beige: 'rgba(199, 182, 164, 0.8)',
  Dunkelgrau: 'rgba(109, 110, 113, 0.8)',
  Grau: 'rgba(167, 169, 172, 0.8)',
  Hellgrau: 'rgba(238, 236, 237, 0.8)',
  Hellgruen: 'rgba(213, 227, 131, 0.8)',
  Mint: 'rgba(114, 200, 182, 0.8)',
  Blau: 'rgba(134, 212, 232, 0.8)',
}

export const positions = {
  left: 'left',
  right: 'right',
  bottom: 'bottom',
}
