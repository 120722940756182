import * as React from 'react'
import styled from 'styled-components'
import Image from '../Image'
import Text from '../Text'
import { ms } from 'modules/browser/const'
import * as t from './types'

type Props = {
  gridArea: string
  imagePosition: 'left' | 'right'
  imageWidth: number
  imageBreak: 'LAPTOP' | 'TABLET'
  textProps: {
    children: string
    centered: boolean
    verticalCentered: boolean
    bordered: boolean
  }
  imageProps: {
    bordered: boolean
    centered: boolean
    stretch: boolean
    src: string
    link?: string
    label: string
    alt: string
    eecTrack?: boolean
    context: {
      base64: string
    }
    linkAttributes?: {
      key: string
      value: string
    }[]
    __version: number
    
  }
  context: t.Context
}

export default function TextWithImage({
  textProps,
  imageProps,
  context,
  imagePosition,
  imageWidth,
  imageBreak,
  gridArea,
}: Props) {
  return (
    <Wrapper className="TextWithImage" {...{ imagePosition, imageWidth, imageBreak }}>
      <div className="image">
        <Image
          {...imageProps}
          {...{ imageWidth, imageBreak }}
          context={context}
          gridArea={gridArea}
        />
      </div>
      <Text {...textProps} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > .image {
    width: 100%;
  }

  @media (min-width: ${(props) => ms[props.imageBreak]}px) {
    > .Text {
      margin-top: -10px;
      > blockquote:first-child {
        margin-top: 10px;
      }
      blockquote {
        display: grid;
      }
    }
    > .image {
      float: ${(props) => props.imagePosition};
      width: calc(${(props) => props.imageWidth || 0}% - 11px);

      ${(props) => (props.imagePosition === 'left' ? 'margin-right: 29px' : 'margin-left: 29px')};
    }
  }
`
