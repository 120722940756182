import { Converter } from 'showdown'
import cheerio from 'cheerio'
import formatUrl from './formatUrl'
import { library, icon } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

const fasMapping = Object.values(fas).reduce((p, n) => {
  p[n.iconName] = true
  return p
}, {})
const farMapping = Object.values(far).reduce((p, n) => {
  p[n.iconName] = true
  return p
}, {})
const fabMapping = Object.values(fab).reduce((p, n) => {
  p[n.iconName] = true
  return p
}, {})

library.add(fas, far, fab)

const converter = new Converter({
  tables: true,
  tablesHeaderId: true,
  literalMidWordUnderscores: true,
  extensions: [
    {
      type: 'output',
      regex: new RegExp('--(.{0,15})--', 'g'),
      replace: '<span class="fa-icon $1"></span>',
    },
    {
      type: 'output',
      regex: new RegExp('<p><button>(.*)</button></p>', 'g'),
      replace: '<p><center><button>$1</button></center></p>',
    },
  ],
})

export const makeHtml = (markdown) => {
  if (!markdown) return ''
  const html = converter.makeHtml(markdown)
  const $ = cheerio.load(
    html /*{
    xml: {
      withDomLvl1: false,
      normalizeWhitespace: true
    }
  }*/
  )
  $('a').each(function(_, el) {
    const $el = $(el)
    const href = $el.attr('href') || ''
    $el.attr('href', formatUrl(href))

    if (href.startsWith('http')) {
      $el.attr('target', '_blank')
    }
  })

  $('.fa-icon').each(function(_, el) {
    const $el = $(el)
    // @ts-ignore
    const iconName: any = $el.attr('class').split(/\s+/)[1]
    const prefix = fasMapping[iconName]
      ? 'fas'
      : fabMapping[iconName]
      ? 'fab'
      : farMapping[iconName]
      ? 'far'
      : 'fas'
    const faIcon = icon({ prefix, iconName })
    faIcon && $el.replaceWith(faIcon.html[0])
  })

  $('button > a').each(function(_, el) {
    const $el = $(el)
    const $button = $el.parent()
    $el.attr('class', 'button-like')
    $button.replaceWith($el)
  })

  $('h2,h3,h4,h5,h6').each(function(_, el) {
    const $el = $(el)
    // @ts-ignore
    const tag = $el['0'].name
    const $new = $(`
      <div class='h-wrapper'>
        <div id="${$el.attr('id')}" class='anchor'> </div>
        <${tag}>${$el.html()}</${tag}>
      </div>
    `)
    $el.replaceWith($new)
  })

  const result = $('body').html()

  return result ? result.trim() : ''
}
