import * as React from 'react'
import styled from 'styled-components'
import * as t from './types'
import Link from 'theme/atoms/Link'
import ArrowSvg from 'svg/Arrow'
import { colors } from './const'
import ty from 'utils/typographie'
import useInView from 'hooks/useInView'
import * as actions from './actions'
import useLazyImageSrc from 'hooks/useLazyImageSrc'
import { ms } from 'modules/browser/const'

let isAdmin = false
if (typeof window !== 'undefined') {
  // @ts-ignore
  isAdmin = window.document.isStorybook || false
}

export default function BannerBrand(props: t.Props) {
  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)
  const [ref, image] = useLazyImageSrc(props.context.optImg, props.context.base64, 100)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => props.eecTrack && actions.click(props)

  return (
    <Wrapper bg={props.background} ref={wrapperRef} onClick={handleClick}>
      {props.title && <h4>{props.title}</h4>}
      <div className="image-wrapper">
        <img ref={ref} src={image} alt={props.alt} />
        {props.links.map((link) => (
          <AbsoluteLink key={link.to} {...link} />
        ))}
      </div>
      {props.buttonLabel && <span className="btn">{props.buttonLabel}</span>}
      {props.linkLabel && (
        <span className="link">
          <Link to={props.link}>
            {props.linkLabel} <ArrowSvg color={colors[props.background].text} />
          </Link>
        </span>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  padding: 60px 20px;
  background: ${(p) => colors[p.bg].bg};
  color: ${(p) => colors[p.bg].text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    color: ${(p) => colors[p.bg].text};
  }

  > h4 {
    ${ty([26, 0.46, 34], 'Cormorant Garamond', '500')}
    margin: 0;
    text-align: center;
  }

  > a,
  span {
    color: ${(p) => colors[p.bg].text};
    &:hover {
      color: ${(p) => colors[p.bg].text};
    }
    cursor: pointer;
  }

  > .image-wrapper {
    width: 100%;
    position: relative;
    > img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  > .link {
    white-space: nowrap;
    align-items: center;
    ${ty([16, 0.51, 20], 'Cormorant Garamond', 'bold', 'italic')}
    > svg {
      margin-left: 15px;
    }
    > a {
      text-decoration: none;
      color: ${(p) => colors[p.bg].text};
    }
  }

  > .btn {
    border: 1px solid ${(p) => colors[p.bg].text};
    ${ty([11, 2.4, 20], 'Raleway', 'bold')}
    padding: 10px 20px;
    text-transform: uppercase;
    white-space: nowrap;
    display: flex;
    justify-content: center;
  }

  @media (min-width: ${ms.LAPTOP}px) {
    padding: 100px 80px;
    min-height: 550px;
    > h4 {
      ${ty([36, 0.7, 48])}
    }

    > a,
    span {
      margin-left: 40px;
    }

    > .link {
      ${ty([18, 0.34, 26])}
    }
  }
`
const AbsoluteLink = styled(Link).attrs((props) => ({
  style: {
    top: props.y / 10 + '%',
    left: props.x / 10 + '%',
    width: props.w / 10 + '%',
    height: props.h / 10 + '%',
  },
}))`
  background: ${isAdmin ? 'rgba(0,0,0, 0.5)' : 'none'};
  position: absolute;
`
