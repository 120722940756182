import config from 'config'
import { createSearchHelper } from 'utils/searchClient'

export const createContext = async (props) => {
  const helper = createSearchHelper(config.indexes.products, {
    disjunctiveFacets: ['objectID'],
  })

  props.objectIds.forEach((id: string) => helper.addDisjunctiveFacet('objectID', id))

  const { hits } = await helper.search()

  // @ts-ignore
  const dict = hits.reduce((p, n) => (p[n.objectID] = n) && p, {})

  // @ts-ignore
  return { products: props.objectIds.map((id) => dict[id]).filter((_) => _) }
}
