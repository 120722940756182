import useLazyImageSrc from 'hooks/useLazyImageSrc'
import * as React from 'react'
import styled, { css } from 'styled-components'
import * as t from './types'
import ty from 'utils/typographie'
import ArrowSvg from 'svg/Arrow'
import Link from 'theme/atoms/Link'
import { ms } from 'modules/browser/const'
import useInView from 'hooks/useInView'
import * as actions from './actions'

export default function CategoryBannerBorder(props: t.Props) {
  const [ref, image] = useLazyImageSrc(
    props.context.optImg,
    props.context.base64,
    100,
  )
  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => props.eecTrack && actions.click(props)

  return (
    <Wrapper
      overlayPosition={props.overlayPosition}
      overlayPositionTopBottom={props.overlayPositionTopBottom}
      ref={wrapperRef}
      onClick={handleClick}
      to={props.link}
    >
      <div className="border"></div>
      <Label
        overlayPosition={props.overlayPosition}
        overlayPositionTopBottom={props.overlayPositionTopBottom}
      >
        <div className="content">
          <h3 className="title">{props.title}</h3>
          <div className="subtitle" dangerouslySetInnerHTML={{ __html: props.subtitle }} />
          <div className="link">
            <span>
              {props.linktext} <ArrowSvg />
            </span>
          </div>
        </div>
        <img ref={ref} src={image} alt={props.alt}></img>
      </Label>
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  position: relative;
  display: block;
  text-decoration: none;
  color: #262626;
  &:hover {
    color: #262626;
  }

  > .border {
    position: absolute;
    top: 0%;
    width: calc(100% - 15px);
    height: calc(100% - 15px);
    border: 1.35px solid #555555;
    margin-bottom: 15px;

    ${(props) =>
      props.overlayPosition === 'left'
        ? css``
        : css`
            margin-left: 15px;
          `};

    @media (min-width: ${ms.LAPTOP_L}px) {
      width: calc(100% - 30px);
      height: calc(100% - 40px);
    }
  }
`
const Label = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;

  > .content {
    z-index: 3;
    width: 100%;

    ${(props) =>
      props.overlayPositionTopBottom === 'top'
        ? css`
            order: 0;
            margin-top: 20px;
            margin-bottom: 10px;

            @media (min-width: ${ms.LAPTOP_L}px) {
              margin-top: 30px;
            }
          `
        : css`
            order: 2;
            margin-top: 15px;
            margin-bottom: 30px;
          `};

    ${(props) =>
      props.overlayPosition === 'left'
        ? css`
            text-align: left;
            padding-left: 15px;
            padding-right: 30px;

            @media (min-width: ${ms.LAPTOP_L}px) {
              padding-left: 40px;
              padding-right: 80px;
            }
          `
        : css`
            text-align: right;
            padding-left: 30px;
            padding-right: 15px;

            @media (min-width: ${ms.LAPTOP_L}px) {
              padding-left: 80px;
              padding-right: 40px;
            }
          `};

    > .title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin: 0;
      ${ty([22, 0.84, 28], 'Cormorant Garamond', '500')}
      margin-bottom: 10px;

      @media (min-width: ${ms.LAPTOP_L}px) {
        ${ty([26, 0.46, 36], 'Cormorant Garamond', '500')}
        margin-bottom: 15px;
      }
    }
    > .subtitle {
      min-height: 60px;
      margin-bottom: 10px;

      > p {
        height: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        margin: 0px;
        color: #262626;
        ${ty([14, 0.42, 20], 'Open Sans')}

        @media (min-width: ${ms.LAPTOP_L}px) {
          ${ty([16, 0.48, 24], 'Open Sans')}
          height: 72px;
        }
      }

      @media (min-width: ${ms.LAPTOP_L}px) {
        margin-bottom: 15px;
      }
    }

    > .link {
      > span {
        ${ty([16, 0.51, 20], 'Cormorant Garamond', 'normal', 'italic')}
        color: #262626;
        text-decoration: none;

        @media (min-width: ${ms.LAPTOP_L}px) {
          ${ty([18, 0.34, 26], 'Cormorant Garamond')}
        }
        > svg {
          margin-left: 14px;
        }
      }
      a:visited {
        color: #262626;
      }
    }
  }

  > img {
    width: 100%;

    ${(props) =>
      props.overlayPosition === 'left'
        ? css`
            padding-left: 15px;

            @media (min-width: ${ms.LAPTOP_L}px) {
              padding-left: 40px;
            }
          `
        : css`
            padding-right: 15px;

            @media (min-width: ${ms.LAPTOP_L}px) {
              padding-right: 40px;
            }
          `};
  }
`
