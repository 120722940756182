// @flow
import * as React from 'react'

type Props = {
  src: string
  placeholder: string
}

const scriptId = 'embedly-platform'

export default function Embedly(props: Props) {
  React.useEffect(() => {
    if (document.getElementById(scriptId)) return
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = scriptId
    script.async = true
    script.src = 'https://cdn.embedly.com/widgets/platform.js'
    // $FlowFixMe
    document.body.appendChild(script)
  }, [])

  return (
    <div
      className="Embedly"
      dangerouslySetInnerHTML={{
        __html: `
      <a href="${props.src}" class="embedly-card" data-card-width="100%" data-card-controls="0">
        ${props.placeholder}
      </a>
    `,
      }}
    />
  )
}
