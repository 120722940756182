import toBase64 from 'utils/toBase64'
import { transformExistingImgSrc } from 'utils/imgOptimization'
import { makeHtml } from 'utils/transformMarkdown'
import * as t from './types'
import {createOptimizedImage} from 'utils/imgOptimization'

export const createContext = async (
  props: t.UserConfig,
  { getGridContext }
): Promise<t.Context> => {
  const gridContext = getGridContext()
  let base64 = await toBase64(props.src)
  return { 
    base64: base64, 
    optImg: createOptimizedImage(props.src, gridContext)
  }
}

// export const createServerContext = async (props, req, res) => {}

// export const createContextCacheInvalidation = props => {
//   return props.context.products.map(p => o.objectID)
// }

// export const postprocessProps = async props => props

export const preprocessProps = async (props: t.Props) => {
  return Object.assign({}, props, {
    label: makeHtml(props.label),
  })
}

export const versionUpdate = (props: t.Props) => {
  props = Object.assign({}, props)

  /**
    Migration 11.09.2019: Version 1 - labelLink prop has been removed
   */
  if (!props.__version) {
    props.__version = 1
    if (props.labelLink && !props.label.includes('[')) {
      props.label = `[${props.label}](${props.labelLink})`
    }
    delete props.labelLink
  }

  /**
    Migration 01.05.2021: Version 2 - transform old ImgSources for Image optimization
  */
  if (props.__version === 1) {
    props.__version = 2
    props.src = transformExistingImgSrc(props.src)
  }

  return props
}
