// @flow
import * as React from 'react'

export default function NewsletterSuccessPopup() {
  React.useEffect(() => {
    let script = document.createElement('script')
    script.async = true
    script.src = 'https://wundercurves.int.userwerk.com/uw.js'
    script.type = 'text/javascript'

    const target = document.body.firstChild
    // @ts-ignore
    target.parentNode.insertBefore(script, target)

    // @ts-ignore
    window._uw = window._uw || {}
    // @ts-ignore
    window._uw.first_name = ''
    // @ts-ignore
    window._uw.last_name = ''
    // @ts-ignore
    window._uw.title = ''
    // @ts-ignore
    window._uw.postal_code = ''
    // @ts-ignore
    window._uw.city = ''
    // @ts-ignore
    window._uw.street = ''
    // @ts-ignore
    window._uw.house_number = ''
    // @ts-ignore
    window._uw.email = ''
    // @ts-ignore
    window._uw.birthday = ''
    // @ts-ignore
    window._uw.combined_phone_number = ''
    // @ts-ignore
    window._uw.country = 'DE'

    return () => script.remove()
  }, [])

  return null
}
