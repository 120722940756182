import contentfulBatchFetch from 'utils/contentfulBatchFetch'
import * as t from './types'

type Props = {
  gridArea: string
  city: string
}

type Store = {
  address: string
  city: string
  isPremium: boolean
  name: string
  objectID: string
  openingHoursDescription: string
  phoneNumber: string
  selectionOfSizesDescription: string
  url: string
  addressGeolocation: {
    lat: number
    lon: number
  }
  ownerDescription?: string
  photoGallery?: string[]
  productAssortmentDescription?: string
  selectionOfBrands?: string[]
  socialMediaUrls?: string[]
  paymentMethodDescription?: string
}

export const createContext = async (props: Props) => {
  const hits: Store[] = await contentfulBatchFetch('retailStore')
  const city = props.city.toLowerCase()
  const stores: Store[] = hits.filter((store) => store.city.toLowerCase() === city)

  const mappedStores: t.Store[] = []
  stores.map((store) =>
    mappedStores.push({
      address: store.address,
      city: store.city,
      isPremium: store.isPremium,
      name: store.name,
      objectID: store.objectID,
      openingHoursDescription: store.openingHoursDescription,
      ownerDescription: store.ownerDescription || '',
      phoneNumber: store.phoneNumber,
      photoGallery: store.photoGallery || [''],
      productAssortmentDescription: store.productAssortmentDescription || '',
      selectionOfSizesDescription: store.selectionOfSizesDescription,
      selectionOfBrands: store.selectionOfBrands || [''],
      socialMediaUrls: store.socialMediaUrls || [''],
      paymentMethodDescription: store.paymentMethodDescription || '',
      url: store.url,
      _geoloc: {
        lat: store.addressGeolocation.lat,
        lng: store.addressGeolocation.lon,
      },
    })
  )

  return { stores: mappedStores }
}
