export const versionUpdate = (props) => {
  if (!props.__version) {
    const {
      link42,
      link44,
      link46,
      link48,
      link50,
      link52,
      link54,
      link56,
      link58,
      link60,
      link62,
      link64,
      ...rest
    } = props
    const sizes = [
      { label: '42', link: link42 },
      { label: '44', link: link44 },
      { label: '46', link: link46 },
      { label: '48', link: link48 },
      { label: '50', link: link50 },
      { label: '52', link: link52 },
      { label: '54', link: link54 },
      { label: '56', link: link56 },
      { label: '58', link: link58 },
      { label: '60', link: link60 },
      { label: '62', link: link62 },
      { label: '64', link: link64 },
    ]
    props = { ...rest, sizes, __version: 1 }
  }

  return props
}
